import { isLocalStorageAvailable } from '~/common/sentry-utilities';

const sudoStorageKey = '@expo/sudoUpgradeFinished';

type StorageOptions = {
  checkAvailable?: boolean;
  throwMissing?: boolean;
};

export function checkSudoStorage({
  checkAvailable = true,
  throwMissing = true,
}: StorageOptions = {}) {
  if (checkAvailable && !isLocalStorageAvailable()) {
    if (throwMissing) {
      throw new Error('LocalStorage is not available!');
    } else {
      return undefined;
    }
  }

  return localStorage.getItem(sudoStorageKey) === 'true';
}

export function setSudoStorageEnabled({
  checkAvailable = true,
  throwMissing = true,
}: StorageOptions = {}) {
  if (checkAvailable && !isLocalStorageAvailable()) {
    if (throwMissing) {
      throw new Error('LocalStorage is not available!');
    } else {
      return;
    }
  }

  localStorage.setItem(sudoStorageKey, 'true');
}

export function clearSudoStorage({
  checkAvailable = true,
  throwMissing = true,
}: StorageOptions = {}) {
  if (checkAvailable && !isLocalStorageAvailable()) {
    if (throwMissing) {
      throw new Error('LocalStorage is not available!');
    } else {
      return;
    }
  }

  localStorage.removeItem(sudoStorageKey);
}

export function createPopup(url: string, onCancel: () => void) {
  if (!isLocalStorageAvailable()) {
    throw new Error('LocalStorage is not available!');
  }

  return new Promise<void>((resolve, reject) => {
    const width = Math.min(window.outerWidth, 500);
    const height = Math.min(window.outerHeight, 1.5 * width);
    const left = window.screenX + (window.outerWidth - width) * 0.5;
    const top = window.screenY + (window.outerHeight - height) * 0.4;

    const windowFeatures = `popup=true,width=${width},height=${height},top=${top},left=${left}`;

    clearSudoStorage({ checkAvailable: false });
    const popup = window.open(url, 'Expo', windowFeatures);

    if (!popup) {
      return reject(new Error(`Expo popup was unable to be created`));
    }

    const checkPopup = setInterval(() => {
      try {
        if (popup.closed) {
          onCancel();
        } else if (checkSudoStorage({ checkAvailable: false })) {
          popup.close();
        } else {
          return;
        }

        clearInterval(checkPopup);
        clearSudoStorage();
        resolve();
      } catch (error) {
        clearInterval(checkPopup);
        clearSudoStorage();
        reject(error);
      }
    }, 500);
  });
}
