import { SnackLogo } from '@expo/styleguide';
import { BranchIcon } from '@expo/styleguide-icons/custom/BranchIcon';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { CredentialIcon } from '@expo/styleguide-icons/custom/CredentialIcon';
import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { ReceiptIcon } from '@expo/styleguide-icons/custom/ReceiptIcon';
import { Smartphone01Icon } from '@expo/styleguide-icons/custom/Smartphone01Icon';
import { Smartphone02Icon } from '@expo/styleguide-icons/custom/Smartphone02Icon';
import { ArrowLeftIcon } from '@expo/styleguide-icons/outline/ArrowLeftIcon';
import { Bell03Icon } from '@expo/styleguide-icons/outline/Bell03Icon';
import { BracketsXIcon } from '@expo/styleguide-icons/outline/BracketsXIcon';
import { Cloud01Icon } from '@expo/styleguide-icons/outline/Cloud01Icon';
import { Coins01Icon } from '@expo/styleguide-icons/outline/Coins01Icon';
import { CreditCard02Icon } from '@expo/styleguide-icons/outline/CreditCard02Icon';
import { Cube02Icon } from '@expo/styleguide-icons/outline/Cube02Icon';
import { DataIcon } from '@expo/styleguide-icons/outline/DataIcon';
import { Dataflow03Icon } from '@expo/styleguide-icons/outline/Dataflow03Icon';
import { FileSearch02Icon } from '@expo/styleguide-icons/outline/FileSearch02Icon';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { Grid01Icon } from '@expo/styleguide-icons/outline/Grid01Icon';
import { Key01Icon } from '@expo/styleguide-icons/outline/Key01Icon';
import { LayersTwo02Icon } from '@expo/styleguide-icons/outline/LayersTwo02Icon';
import { LayoutAlt02Icon } from '@expo/styleguide-icons/outline/LayoutAlt02Icon';
import { LineChartUp02Icon } from '@expo/styleguide-icons/outline/LineChartUp02Icon';
import { ListIcon } from '@expo/styleguide-icons/outline/ListIcon';
import { MessageXSquareIcon } from '@expo/styleguide-icons/outline/MessageXSquareIcon';
import { NotificationBoxIcon } from '@expo/styleguide-icons/outline/NotificationBoxIcon';
import { Settings02Icon } from '@expo/styleguide-icons/outline/Settings02Icon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';
import { ZapFastIcon } from '@expo/styleguide-icons/outline/ZapFastIcon';
import { ZapSquareIcon } from '@expo/styleguide-icons/outline/ZapSquareIcon';
import { useContext } from 'react';

import { useEnableNotificationsUsageUI } from '~/common/gating/gates/EnableNotificationsUsageUI';
import { useEnvironmentVariablesFeatureGate } from '~/common/gating/gates/EnvironmentVariablesFeatureGate';
import { useServerlessDeploymentsFeatureGate } from '~/common/gating/gates/ServerlessDeploymentsFeatureGate';
import { useViewAuditLogFeatureGate } from '~/common/gating/gates/ViewAuditLogFeatureGate';
import { getProjectURL, isTeamAccount, isUserAccount } from '~/common/helpers';
import { SidebarCollapsiblesContext } from '~/providers/SidebarCollapsiblesContext';
import { useAccountIsPlan } from '~/providers/useAccountIsPlan';
import {
  isBillingPageDisabled,
  isDeveloperOrImplicitOwner,
  isEnvironmentVariablesPageDisabled,
} from '~/scenes/Settings/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { SidebarCollapsible } from '~/ui/components/Sidebar/SidebarCollapsible';
import { SidebarLink } from '~/ui/components/Sidebar/SidebarLink';
import { SidebarSectionHeader } from '~/ui/components/Sidebar/SidebarSectionHeader';
import { SidebarItem, getPageName } from '~/ui/components/SidebarNavigation/helpers';

type Props = Pick<LoggedInProps, 'currentUser' | 'accountName'> & {
  selectedSidebarItem?: SidebarItem | null;
  onClick?: () => void;
  projectName: string;
  serverlessDeploymentId?: string;
};

export function SidebarLinks({
  currentUser,
  accountName,
  projectName,
  selectedSidebarItem,
  onClick,
  serverlessDeploymentId,
}: Props) {
  const urlAccountName = encodeURIComponent(accountName);
  const projectBaseUrl = getProjectURL(accountName, projectName);
  const account = currentUser?.accounts?.find((account) => account.name === accountName);

  const noPermissionData = !accountName || !currentUser?.username;
  const isPersonalAccountOfSSOUser = account?.ownerUserActor?.__typename === 'SSOUser';
  const isServelessDetailsMenu = Boolean(serverlessDeploymentId);

  const areBillingItemsDisabled = isBillingPageDisabled(currentUser, accountName);
  const areEnvironmentVariablesDisabled = isEnvironmentVariablesPageDisabled(
    currentUser,
    noPermissionData,
    account
  );

  const {
    isAccountOpen,
    isProjectOpen,
    setIsAccountOpen,
    setIsProjectOpen,
    isServerlessOpen,
    setIsServerlessOpen,
  } = useContext(SidebarCollapsiblesContext);
  const isAuditLogEnabled = useViewAuditLogFeatureGate().isEnabled();
  const accountIsEnterprise = useAccountIsPlan(accountName, 'Enterprise');
  const isEnvironmentVariablesEnabled = useEnvironmentVariablesFeatureGate().isEnabled();
  const shouldShowServerless = useServerlessDeploymentsFeatureGate().isEnabled();

  const generalSection = (
    <>
      {projectName && !isServelessDetailsMenu && (
        <SidebarLink
          title="Back to Dashboard"
          Icon={ArrowLeftIcon}
          href={`/accounts/${urlAccountName}`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {projectName && isServelessDetailsMenu && (
        <SidebarLink
          title="Back to Deployments"
          Icon={ArrowLeftIcon}
          href={`/accounts/${urlAccountName}/projects/${projectName}/serverless/deployments`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Dashboard"
          Icon={LayoutAlt02Icon}
          href={`/accounts/${urlAccountName}`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Projects"
          Icon={FolderIcon}
          href={`/accounts/${urlAccountName}/projects`}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_PROJECTS}
          onClick={onClick}
        />
      )}
      {!projectName && isUserAccount(accountName ?? '', currentUser) && (
        <SidebarLink
          title="Snacks"
          Icon={SnackLogo}
          href={`/accounts/${urlAccountName}/snacks`}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SNACKS}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Usage"
          href={`/accounts/${urlAccountName}/settings/usage`}
          Icon={LineChartUp02Icon}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_USAGE}
          onClick={onClick}
        />
      )}
    </>
  );

  const isPushNotificationsSidebarLinkEnabled = useEnableNotificationsUsageUI().isEnabled();

  const projectSection = (
    <>
      {projectName && !isServelessDetailsMenu && (
        <>
          <SidebarSectionHeader title="Project" />
          <SidebarLink
            title="Overview"
            href={projectBaseUrl}
            Icon={Grid01Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_OVERVIEW}
            onClick={onClick}
          />
          <SidebarLink
            title="Deployments"
            href={`${projectBaseUrl}/deployments`}
            Icon={Dataflow03Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_DEPLOYMENTS}
            onClick={onClick}
          />
          <SidebarLink
            title="Submissions"
            href={`${projectBaseUrl}/submissions`}
            Icon={EasSubmitIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_SUBMISSIONS}
            onClick={onClick}
          />
          <SidebarLink
            title="Insights"
            href={`${projectBaseUrl}/insights`}
            Icon={DataIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_INSIGHTS}
            onClick={onClick}
          />
          <SidebarLink
            title="Development builds"
            href={`${projectBaseUrl}/development-builds`}
            Icon={Smartphone01Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_DEVELOPMENT_BUILDS}
            onClick={onClick}
          />
          <SidebarLink
            title="Builds"
            href={`${projectBaseUrl}/builds`}
            Icon={BuildIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_BUILDS}
            onClick={onClick}
          />
          <SidebarLink
            title="Channels"
            href={`${projectBaseUrl}/channels`}
            Icon={Cube02Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_CHANNELS}
            onClick={onClick}
          />
          <SidebarLink
            title="Branches"
            href={`${projectBaseUrl}/branches`}
            Icon={BranchIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_BRANCHES}
            onClick={onClick}
          />
          <SidebarLink
            title="Updates"
            href={`${projectBaseUrl}/updates`}
            Icon={LayersTwo02Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_UPDATES}
            onClick={onClick}
          />
          {isPushNotificationsSidebarLinkEnabled && (
            <SidebarLink
              title="Push notifications"
              href={`${projectBaseUrl}/push-notifications`}
              Icon={NotificationBoxIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_PUSH_NOTIFICATIONS}
              onClick={onClick}
            />
          )}
          {shouldShowServerless ? (
            <SidebarCollapsible
              title="Serverless"
              Icon={Cloud01Icon}
              defaultOpen={isServerlessOpen}
              onOpenChange={setIsServerlessOpen}>
              <SidebarLink
                title="Deployments"
                href={`${projectBaseUrl}/serverless/deployments`}
                Icon={ZapSquareIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS}
                onClick={onClick}
              />
              <SidebarLink
                title="Metrics"
                href={`${projectBaseUrl}/serverless/metrics`}
                Icon={ZapFastIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_METRICS}
                onClick={onClick}
              />
              <SidebarLink
                title="Crashes"
                href={`${projectBaseUrl}/serverless/crashes`}
                Icon={MessageXSquareIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_CRASHES}
                onClick={onClick}
              />
              <SidebarLink
                title="Requests"
                href={`${projectBaseUrl}/serverless/requests`}
                Icon={ListIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_REQUESTS}
                onClick={onClick}
              />
              <SidebarLink
                title="Settings"
                href={`${projectBaseUrl}/serverless/settings`}
                Icon={Settings02Icon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_SETTINGS}
                onClick={onClick}
              />
            </SidebarCollapsible>
          ) : null}
          <SidebarCollapsible
            title="Project settings"
            Icon={Settings02Icon}
            defaultOpen={isProjectOpen}
            onOpenChange={setIsProjectOpen}>
            <SidebarLink
              title="General"
              href={`${projectBaseUrl}/settings`}
              Icon={Grid01Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_OVERVIEW}
              onClick={onClick}
            />
            <SidebarLink
              title="Credentials"
              href={`${projectBaseUrl}/credentials`}
              Icon={CredentialIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_CREDENTIALS}
              disabled={
                !currentUser?.isExpoAdmin &&
                (noPermissionData ||
                  !account ||
                  !isDeveloperOrImplicitOwner(account, currentUser?.username))
              }
              disabledHint="Users with Viewer role cannot access account-wide credentials. If you need access please contact your organization Admin."
              onClick={onClick}
            />
            <SidebarLink
              title="GitHub"
              href={`${projectBaseUrl}/github`}
              Icon={GithubIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_GITHUB}
              onClick={onClick}
            />
            <SidebarLink
              title="Secrets"
              href={`${projectBaseUrl}/secrets`}
              Icon={Key01Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_SECRETS}
              onClick={onClick}
            />
            {isEnvironmentVariablesEnabled && (
              <SidebarLink
                title="Environment variables"
                href={`${projectBaseUrl}/environment-variables`}
                Icon={BracketsXIcon}
                isSelected={
                  selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_ENVIRONMENT_VARIABLES
                }
                onClick={onClick}
                disabled={areEnvironmentVariablesDisabled}
                disabledHint="Users with Viewer role cannot access project-wide environment variables. If you need access please contact your organization Admin."
              />
            )}
          </SidebarCollapsible>
        </>
      )}
    </>
  );

  const serverlessSection = (
    <>
      {projectName && isServelessDetailsMenu && (
        <>
          <SidebarSectionHeader title="Deployment Details" />
          <SidebarLink
            title="Overview"
            href={`${projectBaseUrl}/serverless/deployments/${serverlessDeploymentId}`}
            Icon={ZapSquareIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_OVERVIEW}
            onClick={onClick}
          />
          <SidebarLink
            title="Metrics"
            href={`${projectBaseUrl}/serverless/deployments/${serverlessDeploymentId}/metrics`}
            Icon={ZapFastIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_METRICS}
            onClick={onClick}
          />
          <SidebarLink
            title="Logs"
            href={`${projectBaseUrl}/serverless/deployments/${serverlessDeploymentId}/logs`}
            Icon={ListIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_LOGS}
            onClick={onClick}
          />
        </>
      )}
    </>
  );

  const accountSettingsSection = (
    <>
      {!projectName && (
        <SidebarCollapsible
          title={getPageName(
            isUserAccount(accountName ?? '', currentUser),
            isTeamAccount(accountName ?? '', currentUser)
          )}
          Icon={Settings02Icon}
          defaultOpen={isAccountOpen}
          onOpenChange={setIsAccountOpen}>
          <SidebarLink
            title="Overview"
            href={`/accounts/${urlAccountName}/settings`}
            Icon={Grid01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_OVERVIEW}
            onClick={onClick}
          />
          <SidebarLink
            title="Members"
            href={`/accounts/${urlAccountName}/settings/members`}
            Icon={Users01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_MEMBERS}
            onClick={onClick}
          />
          <SidebarLink
            title="Access tokens"
            href={`/accounts/${urlAccountName}/settings/access-tokens`}
            Icon={Coins01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_ACCESS_TOKENS}
            onClick={onClick}
          />
          <SidebarLink
            title="Credentials"
            href={`/accounts/${urlAccountName}/settings/credentials`}
            Icon={CredentialIcon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_CREDENTIALS}
            onClick={onClick}
            disabled={
              !currentUser?.isExpoAdmin &&
              (!account ||
                !currentUser?.username ||
                !isDeveloperOrImplicitOwner(account, currentUser?.username))
            }
            disabledHint="Users with Viewer role cannot access account-wide credentials. If you need access please contact your organization Admin."
          />
          <SidebarLink
            title="Apple devices"
            href={`/accounts/${urlAccountName}/settings/apple-devices`}
            Icon={Smartphone02Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_APPLE_DEVICES}
            onClick={onClick}
          />
          {isAuditLogEnabled && (accountIsEnterprise || currentUser.isExpoAdmin) && (
            <SidebarLink
              title="Audit logs"
              href={`/accounts/${urlAccountName}/settings/audit-logs`}
              Icon={FileSearch02Icon}
              isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_AUDIT_LOGS}
              onClick={onClick}
            />
          )}
          <SidebarLink
            title="Secrets"
            href={`/accounts/${urlAccountName}/settings/secrets`}
            Icon={Key01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_SECRETS}
            onClick={onClick}
          />
          {isEnvironmentVariablesEnabled && (
            <SidebarLink
              title="Environment variables"
              href={`/accounts/${urlAccountName}/settings/environment-variables`}
              Icon={BracketsXIcon}
              isSelected={
                selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_ENVIRONMENT_VARIABLES
              }
              onClick={onClick}
              disabled={areEnvironmentVariablesDisabled}
              disabledHint="Users with Viewer role cannot access account-wide environment variables. If you need access please contact your organization Admin."
            />
          )}
          <SidebarLink
            title="Email notifications"
            href={`/accounts/${urlAccountName}/settings/email-notifications`}
            Icon={Bell03Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS}
            onClick={onClick}
          />
          <SidebarLink
            title="Billing"
            href={`/accounts/${urlAccountName}/settings/billing`}
            Icon={CreditCard02Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_BILLING}
            onClick={onClick}
            disabled={areBillingItemsDisabled}
            disabledHint={
              isPersonalAccountOfSSOUser
                ? 'Billing is not available for SSO user personal accounts.'
                : 'Members with a Developer or Viewer role cannot access this page. Contact a member with an Owner or Admin role to gain access.'
            }
          />
          <SidebarLink
            title="Receipts"
            href={`/accounts/${urlAccountName}/settings/receipts`}
            Icon={ReceiptIcon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_RECEIPTS}
            onClick={onClick}
            disabled={areBillingItemsDisabled}
            disabledHint={
              isPersonalAccountOfSSOUser
                ? 'Billing is not available for SSO user personal accounts.'
                : 'Members with a Developer or Viewer role cannot access this page. Contact a member with an Owner or Admin role to gain access.'
            }
          />
        </SidebarCollapsible>
      )}
    </>
  );

  return (
    <>
      {generalSection}
      {accountSettingsSection}
      {projectSection}
      {serverlessSection}
    </>
  );
}
