import { mergeClasses } from '@expo/styleguide';
import type { HTMLAttributes, ReactNode } from 'react';

import { CALLOUT, H3, H4, TextElementUnion } from '../text';

export type BoxHeaderProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: ReactNode;
  icon?: ReactNode;
  suffix?: ReactNode;
  description?: ReactNode;
  withDivider?: boolean;
  hideOverflow?: boolean;
  rightSlot?: ReactNode;
  size?: 'md' | 'sm';
  tag?: TextElementUnion;
  textClassName?: string;
};

export function BoxHeader({
  title,
  icon,
  suffix,
  description,
  withDivider,
  hideOverflow = false,
  rightSlot,
  className,
  size = 'md',
  tag,
  textClassName,
}: BoxHeaderProps) {
  const HeaderElement = size === 'sm' ? H4 : H3;

  return (
    <div
      className={mergeClasses(
        'flex items-center justify-between gap-y-4 max-md-gutters:flex-col max-md-gutters:items-start',
        withDivider && 'mb-4 border-b border-b-default pb-4',
        Boolean(rightSlot) && 'gap-x-8',
        className
      )}>
      <div className="grid gap-1 overflow-hidden">
        <div
          className={mergeClasses(
            'grid items-center gap-3 overflow-hidden',
            Boolean(suffix && !icon) && 'grid-cols-[min-content,min-content]',
            Boolean(suffix && icon) && 'grid-cols-[min-content,min-content,min-content]',
            Boolean(!suffix && icon) && 'grid-cols-[min-content,auto]',
            Boolean(!suffix && !icon) && 'grid-cols-auto-min-1'
          )}>
          {icon}
          <HeaderElement
            tag={tag}
            className={mergeClasses(textClassName, hideOverflow && 'truncate')}>
            {title}
          </HeaderElement>
          {suffix}
        </div>
        {!!description && typeof description !== 'string' && description}
        {!!description && typeof description === 'string' && (
          <CALLOUT theme="secondary" className={mergeClasses(hideOverflow && 'truncate')}>
            {description}
          </CALLOUT>
        )}
      </div>
      {rightSlot}
    </div>
  );
}
