import {
  Button as StyleguideButton,
  ButtonProps as StyleguideButtonProps,
  mergeClasses,
  ButtonTheme,
} from '@expo/styleguide';
import { StatusFailureIcon } from '@expo/styleguide-icons/custom/StatusFailureIcon';
import { CheckIcon } from '@expo/styleguide-icons/outline/CheckIcon';
import { type LinkProps } from 'next/link';
import { forwardRef } from 'react';

import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { FormStates } from '~/ui/components/form/FormStates';

export type ButtonProps = StyleguideButtonProps & {
  status?: FormStates;
  block?: boolean;
  download?: string;
} & Pick<LinkProps, 'prefetch'>;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      disabled,
      leftSlot,
      rightSlot,
      theme = 'primary',
      status = FormStates.IDLE,
      size,
      onClick,
      block,
      children,
      href,
      target,
      type = 'button',
      download,
      className,
      openInNewTab,
      ...rest
    },
    ref
  ) => {
    const statusPresent = status !== FormStates.IDLE;
    const canClick = !statusPresent && !disabled;

    return (
      <StyleguideButton
        type={type}
        theme={theme}
        size={size}
        className={mergeClasses(
          statusPresent && 'opacity-100 disabled:opacity-100',
          block && 'w-full justify-center',
          status === FormStates.SUCCESS && 'border-palette-green9 bg-palette-green9',
          status === FormStates.ERRORED && 'border-palette-red6 bg-palette-red6',
          'relative',
          className
        )}
        disabled={!canClick}
        onClick={canClick ? onClick : undefined}
        {...(href && {
          href: canClick ? href : '#',
          target,
          download,
          openInNewTab,
        })}
        leftSlot={!statusPresent ? leftSlot : undefined}
        rightSlot={!statusPresent ? rightSlot : undefined}
        ref={ref}
        {...rest}>
        {(statusPresent || children) && (
          <>
            {statusPresent && (
              <div className="absolute inset-0 flex items-center justify-center">
                {status === FormStates.LOADING && (
                  <ActivityIndicator className={getIndicatorColor(theme)} />
                )}
                {status === FormStates.SUCCESS && <CheckIcon className="text-palette-white" />}
                {status === FormStates.ERRORED && (
                  <StatusFailureIcon className="text-palette-white" />
                )}
              </div>
            )}
            <div className={mergeClasses('transition-opacity', statusPresent && 'opacity-0')}>
              {children}
            </div>
          </>
        )}
      </StyleguideButton>
    );
  }
);

function getIndicatorColor(theme: ButtonTheme) {
  switch (theme) {
    case 'primary-destructive':
      return 'stroke-button-primary-destructive-icon';
    case 'secondary-destructive':
      return 'stroke-button-secondary-destructive-icon';
    case 'primary':
      return 'stroke-button-primary-icon';
    case 'secondary':
      return 'stroke-button-secondary-icon';
    case 'tertiary':
      return 'stroke-button-tertiary-icon';
    case 'quaternary':
      return 'stroke-button-quaternary-icon';
    default:
      return 'stroke-icon-default';
  }
}

Button.displayName = 'Button';
