import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';

import { Button } from '~/ui/components/Button';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipText } from '~/ui/components/Tooltip/TooltipText';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';

import { TooltipProvider } from '../../Tooltip/TooltipProvider';

export function DocsButton() {
  return (
    // note(simek): To prevent `TooltipProvider` error we moved the global tooltip provider to `Document.tsx`,
    // but since `DocsButton` is part of `Navigation`, which is used directly in `_app.tsx`, it needs its own provider.
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger>
          <Button
            theme="quaternary"
            href="https://docs.expo.dev"
            openInNewTab
            aria-label="Expo documentation"
            className="flex size-9 items-center justify-center rounded-md"
            leftSlot={
              <svg width="20" height="15" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.477 12H7.17719V2.91913H14.477C14.8605 2.91913 15.1715 3.23009 15.1715 3.61368V11.3055C15.1715 11.6891 14.8605 12 14.477 12Z"
                  className="fill-palette-gray10 dark:fill-palette-gray8"
                />
                <path
                  d="M1.14328 12H7.60653V2.91913H1.14328C0.759695 2.91913 0.448736 3.23009 0.448736 3.61368V11.3055C0.448736 11.6891 0.759695 12 1.14328 12Z"
                  className="fill-palette-gray10 dark:fill-palette-gray8"
                />
                <path
                  d="M7.44243 2.75441V11.208C6.25795 10.0361 1.95775 10.2381 1.95775 10.2381V0.892998C1.95775 0.892998 5.71359 0.264251 7.44243 2.75441Z"
                  className="fill-palette-gray7 dark:fill-palette-gray11"
                />
                <path
                  d="M8.19656 2.75441V11.208C9.38104 10.0361 13.6812 10.2381 13.6812 10.2381V0.892998C13.6812 0.892998 9.9254 0.264251 8.19656 2.75441Z"
                  className="fill-palette-gray7 dark:fill-palette-gray11"
                />
              </svg>
            }
          />
        </TooltipTrigger>
        <TooltipContent className="flex flex-row items-center gap-1">
          <TooltipText>Docs</TooltipText>
          <ArrowUpRightIcon className="icon-xs -mr-0.5" />
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
}
