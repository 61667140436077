import { ButtonTheme, mergeClasses } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { MouseEvent, ReactElement, ReactNode } from 'react';

import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { CALLOUT, HEADLINE } from '~/ui/components/text';

import { BoxContentContainer } from './Box/BoxContentContainer';
import { BoxWrapper } from './Box/BoxWrapper';
import { Button } from './Button';

type Props = {
  illustration?: ReactElement;
  icon?: ReactElement;
  title?: string;
  description?: ReactNode;
  href?: string;
  buttonText?: string;
  buttonIcon?: ReactElement | null;
  buttonTheme?: ButtonTheme;
  openInNewTab?: boolean;
  loading?: boolean;
  inline?: boolean;
  className?: string;
  onClick?: (event: MouseEvent) => void;
};

export function Placeholder({
  illustration,
  icon,
  description,
  href,
  className,
  onClick,
  loading,
  inline,
  title = 'Something went wrong',
  buttonText = 'Learn More',
  buttonTheme = 'secondary',
  openInNewTab = true,
  buttonIcon = href ? openInNewTab ? <ArrowUpRightIcon /> : <ArrowRightIcon /> : undefined,
}: Props) {
  return (
    <BoxWrapper
      className={mergeClasses(
        'relative rounded-lg p-16 py-[60px]',
        'max-md-gutters:px-8',
        illustration && 'pt-[76px]',
        inline && 'border-0 bg-transparent shadow-none',
        className
      )}>
      <BoxContentContainer className="flex items-center justify-center">
        {loading ? (
          <ActivityIndicator size="lg" className="stroke-icon-default" />
        ) : (
          <>
            {illustration && (
              <div className="absolute top-12 h-[200px]">
                {illustration}
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-default" />
              </div>
            )}
            <div className="relative flex flex-col items-center justify-center">
              {icon &&
                (illustration ? (
                  <div className="mb-12 rounded-full border border-default bg-default p-5 shadow-md">
                    {icon}
                  </div>
                ) : (
                  <div className="mb-2.5">{icon}</div>
                ))}
              <HEADLINE className="text-center">{title}</HEADLINE>
              {description && (
                <CALLOUT theme="secondary" className="mt-1 text-center">
                  {description}
                </CALLOUT>
              )}
              {(href || onClick) && (
                <Button
                  href={href}
                  theme={buttonTheme}
                  size="xs"
                  className="mt-5 px-4"
                  rightSlot={buttonIcon ?? undefined}
                  openInNewTab={openInNewTab}
                  onClick={onClick}>
                  {buttonText}
                </Button>
              )}
            </div>
          </>
        )}
      </BoxContentContainer>
    </BoxWrapper>
  );
}
