import { useState } from 'react';

import { Button } from '~/ui/components/Button';
import { FormStates } from '~/ui/components/form/FormStates';
import { CALLOUT } from '~/ui/components/text';

import { PartialUserSecondFactorDevice } from './types';

type Props = {
  SMSDevice: PartialUserSecondFactorDevice;
  sendSMSOTPAsync: (deviceId: string) => Promise<void>;
  sentCode?: boolean;
};

export function SMSDevice({ SMSDevice, sendSMSOTPAsync, sentCode }: Props) {
  const [buttonState, setButtonState] = useState(FormStates.IDLE);
  const [buttonText, setButtonText] = useState(sentCode ? 'Re-send Code' : 'Send Code');

  async function _sendSMSOTPAsync(deviceId: string) {
    try {
      setButtonState(FormStates.LOADING);
      await sendSMSOTPAsync(deviceId);
    } catch (error) {
      console.error(error);
    }

    setButtonState(FormStates.IDLE);
    setButtonText('Re-send Code');
  }

  return (
    <div className="my-2 grid grid-cols-[1fr_auto] items-center">
      <CALLOUT>{SMSDevice.smsPhoneNumber}</CALLOUT>
      <Button
        size="xs"
        theme="secondary"
        onClick={() => SMSDevice?.id && _sendSMSOTPAsync(SMSDevice.id)}
        status={buttonState}>
        {buttonText}
      </Button>
    </div>
  );
}
