import { mergeClasses } from '@expo/styleguide';
import { CheckIcon } from '@expo/styleguide-icons/outline/CheckIcon';
import { ChevronDownIcon } from '@expo/styleguide-icons/outline/ChevronDownIcon';
import { ChevronUpIcon } from '@expo/styleguide-icons/outline/ChevronUpIcon';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ComponentType, HTMLAttributes, ReactElement } from 'react';

import { CALLOUT, FOOTNOTE, HEADLINE } from '~/ui/components/text';

import { Button } from './Button';

export type SelectProps<T> = SelectPrimitive.SelectProps<T> & {
  id?: string;
  optionsLabel?: string;
  placeholder?: string;
  testID?: string;
  minWidth?: number | string;
  height?: number;
  xl?: boolean;
  iconSize?: string;
  ariaLabel?: string;
  className?: string;
} & (
    | {
        customItems: ReactElement[];
        options?: never;
      }
    | {
        customItems?: never;
        options?: {
          id: string;
          label: string;
          imageUrl?: string;
          Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
          leftSlot?: ReactElement;
          rightSlot?: ReactElement;
        }[];
      }
  );

export function Select<T = string>({
  options,
  optionsLabel,
  id,
  value,
  onValueChange,
  placeholder,
  testID,
  minWidth,
  ariaLabel,
  className,
  customItems: items,
  disabled = false,
  xl = false,
  height = xl ? 52 : 36,
  iconSize = xl ? 'icon-md' : 'icon-sm',
}: SelectProps<T>) {
  const TextElement = xl ? HEADLINE : CALLOUT;
  return (
    <SelectPrimitive.Root name={id} onValueChange={onValueChange} value={value} disabled={disabled}>
      <SelectPrimitive.Trigger asChild className="flex">
        <Button
          disabled={disabled}
          theme="secondary"
          size={xl ? 'xl' : 'sm'}
          aria-label={ariaLabel}
          rightSlot={<ChevronDownIcon className={mergeClasses('text-icon-secondary', iconSize)} />}
          className={mergeClasses(
            'transform-none justify-between truncate px-3',
            !value && 'text-quaternary',
            xl ? 'min-h-[52px]' : 'min-h-[36px]',
            className
          )}
          style={{ minWidth }}
          {...{ 'data-testid': testID }}>
          <SelectPrimitive.Value
            placeholder={<CALLOUT theme="quaternary">{placeholder}</CALLOUT>}
            aria-label={value}
          />
        </Button>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          ref={(ref) => ref?.addEventListener('touchend', (event) => event.preventDefault())}
          className="relative z-[700] max-w-[87.5vw] overflow-hidden rounded-md border border-default bg-overlay shadow-md"
          data-orientation="horizontal">
          <SelectPrimitive.ScrollUpButton className="flex h-7 items-center justify-center rounded-t-md bg-element">
            <ChevronUpIcon className="icon-sm text-icon-secondary" />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>
            <SelectPrimitive.Group>
              {optionsLabel && (
                <SelectPrimitive.Label>
                  <FOOTNOTE theme="secondary" className="!cursor-default px-3 pb-1 pt-2">
                    {optionsLabel}
                  </FOOTNOTE>
                </SelectPrimitive.Label>
              )}
              {items}
              {options?.map(({ id, label, imageUrl, Icon, rightSlot, leftSlot }) => (
                <SelectPrimitive.Item
                  key={id}
                  value={id}
                  className="flex cursor-pointer items-center justify-between px-3 py-2 hocus:bg-hover hocus:outline-0"
                  style={{ height }}>
                  <SelectPrimitive.ItemText>
                    <TextElement className="flex items-center gap-2 !font-normal" tag="span">
                      {leftSlot}
                      {Icon && (
                        <SelectPrimitive.Icon>
                          <Icon className={iconSize} />
                        </SelectPrimitive.Icon>
                      )}
                      {imageUrl && (
                        <img alt={String(id)} src={imageUrl} className="size-8 rounded-full" />
                      )}
                      {label}
                      {rightSlot}
                    </TextElement>
                  </SelectPrimitive.ItemText>
                  <SelectPrimitive.ItemIndicator>
                    <CheckIcon className={mergeClasses('text-icon-secondary', iconSize)} />
                  </SelectPrimitive.ItemIndicator>
                </SelectPrimitive.Item>
              ))}
            </SelectPrimitive.Group>
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className="flex h-7 items-center justify-center rounded-b-md bg-element">
            <ChevronDownIcon className="icon-sm text-icon-secondary" />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
}
