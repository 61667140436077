import { spacing } from '@expo/styleguide-base';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { useRouter } from 'next/compat/router';
import { useState, ReactNode } from 'react';

import { getProjectURL } from '~/common/helpers';
import { useAppByFullNameQuery } from '~/graphql/queries/AppByFullName.generated';
import { linkResolver } from '~/ui/components/Navigation/linkResolver';
import { ProjectIcon } from '~/ui/components/ProjectIcon';
import { PopoverItem } from '~/ui/components/SidebarNavigation/components/PopoverItem';
import { SidebarSelector } from '~/ui/components/SidebarNavigation/components/SidebarSelector';
import { useProjectSelectorQuery } from '~/ui/components/SidebarNavigation/queries/ProjectSelector.query.generated';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  accountName: string;
  projectSlug?: string;
  onSelect?: (selectedSlug: string) => void;
  header?: ReactNode;
  rightSlot?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  showAllProjects?: boolean;
};

/**
 * If `onSelect()` is provided, selecting will call the function and close the popover.
 * Otherwise, it will navigate to the project page.
 */
export function ProjectSelector({
  accountName,
  projectSlug,
  onSelect,
  header,
  rightSlot,
  disabled,
  placeholder,
  showAllProjects = true,
}: Props) {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);

  const { data } = useProjectSelectorQuery({
    variables: {
      offset: 0,
      limit: 50,
      accountName,
      includeUnpublished: true,
    },
  });

  const { data: app } = useAppByFullNameQuery({
    variables: {
      fullName: `@${accountName}/${projectSlug}`,
    },
  });

  const projects = data?.account.byName.apps ?? [];
  const selectedProject = projects.find((project) => project.slug === projectSlug);

  const fetchedProject = app?.app.byFullName ?? selectedProject;

  const projectName = fetchedProject?.name ?? projectSlug ?? '';
  const projectIcon = fetchedProject?.icon?.url;

  return (
    <SidebarSelector
      disabled={disabled}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label={header ?? 'Project'}
      rightSlot={rightSlot}
      popoverContent={
        <>
          {projects.map((project) => {
            const href = onSelect
              ? undefined
              : linkResolver(getProjectURL(accountName, project.slug), router, {
                  stripQueryParams: ['channel', 'platform', 'releaseChannel'],
                });

            return (
              <PopoverItem
                key={project.id}
                title={project.name}
                icon={
                  <ProjectIcon
                    name={project.name}
                    iconUrl={project.iconUrl}
                    iconSize={spacing[6]}
                    className="min-h-[24px] min-w-[24px]"
                  />
                }
                onClick={() => {
                  onSelect?.(project.slug);
                  setIsVisible(false);
                }}
                isSelected={project.slug === projectSlug}
                href={href}
              />
            );
          })}
          {showAllProjects && (
            <PopoverItem
              title="All projects"
              onClick={() => setIsVisible(false)}
              href={`/accounts/${accountName}/projects`}
              icon={<FolderIcon />}
            />
          )}
        </>
      }
      triggerContent={
        projectSlug ? (
          <>
            <ProjectIcon
              name={projectName}
              iconUrl={projectIcon}
              iconSize={spacing[6]}
              className="min-h-[24px] min-w-[24px]"
            />
            <CALLOUT className="truncate !font-medium">{projectName}</CALLOUT>
          </>
        ) : (
          <CALLOUT theme="secondary" className="truncate !font-medium">
            {placeholder ?? 'Select project'}
          </CALLOUT>
        )
      }
    />
  );
}
