import { mergeClasses } from '@expo/styleguide';
import { Portal, Overlay, Content } from '@radix-ui/react-dialog';
import { ElementRef, ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react';

export const DIALOG_ANIMATION_DISAPPEAR_MS = 150;

type Props = PropsWithChildren<{ className?: string }>;

export const DialogContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ children, className }: Props, forwardedRef) => (
  <>
    <Portal>
      <Overlay
        className={mergeClasses(
          'dialog-overlay absolute z-[600]',
          'data-[state=open]:animate-fadeIn',
          'data-[state=closed]:animate-fadeOut'
        )}>
        <div className="fixed inset-0 bg-[#00000080]" />
      </Overlay>
      <div
        className="fixed left-0 top-0 z-[700] flex h-dvh w-dvw items-center justify-center"
        ref={forwardedRef}>
        <Content
          aria-describedby={undefined}
          className={mergeClasses(
            'dialog-content overflow-hidden rounded-lg outline-0',
            'data-[state=open]:animate-largeSlideUpAndFadeIn',
            'data-[state=closed]:animate-fadeOut'
          )}>
          <div
            className={mergeClasses(
              'backface-hidden left-0 top-0 w-[90vw] max-w-[500px] overflow-hidden',
              'break-words rounded-lg border border-default bg-default text-default shadow-md outline-0',
              className
            )}>
            {children}
          </div>
        </Content>
      </div>
    </Portal>
  </>
));

DialogContent.displayName = 'DialogContent';
