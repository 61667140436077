import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip';
import { useRef } from 'react';
import { createRoot } from 'react-dom/client';

import { type APIV2Client } from '~/common/api-v2-client';
import { createClient } from '~/common/graphql-client';
import { useCurrentUserQuery } from '~/graphql/queries/CurrentUserQuery.query.generated';

import { UpgradeSudoForm } from '.';

type SudoExternalProps = {
  resolve: (value: void | PromiseLike<void>) => void;
  reject: (reason?: any) => void;
  removeDialog: () => void;
  apiV2Client: APIV2Client;
};

function SudoExternal({ resolve, reject, removeDialog, apiV2Client }: SudoExternalProps) {
  const apolloClient = useRef(
    createClient({
      featureGateQueryParams: apiV2Client.featureGateQueryParams,
    })
  );

  const { data } = useCurrentUserQuery({
    client: apolloClient.current,
    onCompleted(data) {
      if (!data.meUserActor) {
        reject(new Error('An authenticated user is required for this action'));
      }
    },
    onError(error) {
      console.error(
        'Attempted to upgrade session to sudo mode but user data could not be retrieved',
        error
      );
      reject(new Error('Failed to retrieve authenticated user info'));
    },
  });

  const currentUser = data?.meUserActor;
  if (!currentUser) {
    return null;
  }

  return (
    <RadixTooltipProvider>
      <UpgradeSudoForm
        isDialog
        isDialogOpen
        setIsDialogOpen={removeDialog}
        onSuccess={resolve}
        onCancel={() => reject(new Error('Sudo mode upgrade was canceled'))}
        apiV2Client={apiV2Client}
        userIsSSO={currentUser.__typename === 'SSOUser'}
        username={currentUser.username}
      />
    </RadixTooltipProvider>
  );
}

export default function requestSudoUpgrade(apiV2Client: APIV2Client) {
  return new Promise<void>((resolve, reject) => {
    const dialogRoot = document.createElement('div');
    document.body.appendChild(dialogRoot);

    const sudoFormRoot = createRoot(dialogRoot);

    function removeDialog() {
      sudoFormRoot.unmount();
    }

    sudoFormRoot.render(
      <SudoExternal
        resolve={resolve}
        reject={reject}
        removeDialog={removeDialog}
        apiV2Client={apiV2Client}
      />
    );
  });
}
