import Script from 'next/script';
import { useRef } from 'react';

import { isMobileBrowser } from '~/common/window';
import { AddonDetails } from '~/graphql/types.generated';

declare global {
  interface Window {
    rudderanalytics?: any;
  }
}

const dashboardEvents = {
  DASHBOARD_OVERVIEW_BUILD_CLICKED: 'DASHBOARD_OVERVIEW_BUILD_CLICKED',
};

const buildEvents = {
  BUILDS_DELETE_CLICKED: 'BUILDS_DELETE_CLICKED',
  BUILDS_CANCEL_CLICKED: 'BUILDS_CANCEL_CLICKED',
  BUILDS_UPGRADE_CLICKED: 'BUILDS_UPGRADE_CLICKED',
};

const orgConversionEvents = {
  ORG_CONVERSION_STARTED: 'ORG_CONVERSION_STARTED',
  ORG_CONVERSION_COMPLETED: 'ORG_CONVERSION_COMPLETED',
};

const accessTokenEvents = {
  ACCESS_TOKEN_CREATED: 'ACCESS_TOKEN_CREATED',
  ACCESS_TOKEN_DELETED: 'ACCESS_TOKEN_DELETED',
  ACCESS_TOKEN_MODIFIED: 'ACCESS_TOKEN_MODIFIED',
};

const attributionEvents = {
  REFERRAL_RECEIVED: 'REFERRAL_RECEIVED',
};

const twoFactorAuthEvents = {
  USER_2FA_INITIALIZED: 'USER_2FA_INITIALIZED',
  USER_2FA_SAVED_RECOVERY_CODES: 'USER_2FA_SAVED_RECOVERY_CODES',
  USER_2FA_SET_UP: 'USER_2FA_SET_UP',
  USER_2FA_DISABLED: 'USER_2FA_DISABLED',
  USER_2FA_REACHED_SCREEN: 'USER_2FA_REACHED_SCREEN',
};

const GitHubUIEvents = {
  GITHUB_USER_LINKING_STARTED: 'GITHUB_USER_LINKING_STARTED',
  GITHUB_USER_DISCONNECTED: 'GITHUB_USER_DISCONNECTED',
  GITHUB_REPO_LINKING_STARTED: 'GITHUB_REPO_LINKING_STARTED',
  GITHUB_REPO_LINKED: 'GITHUB_REPO_LINKED',
  GITHUB_REPO_LINKING_FAILED: 'GITHUB_REPO_LINKING_FAILED',
  GITHUB_REPO_DISCONNECTED: 'GITHUB_REPO_DISCONNECTED',
  GITHUB_REPO_DISCONNECT_FAILED: 'GITHUB_REPO_DISCONNECT_FAILED',
  GITHUB_COMMIT_LINK_CLICKED: 'GITHUB_COMMIT_LINK_CLICKED',
  MANUAL_GITHUB_BUILD_BUTTON_CLICKED: 'MANUAL_GITHUB_BUILD_BUTTON_CLICKED',
  MANUAL_GITHUB_BUILD_DISPATCHED: 'MANUAL_GITHUB_BUILD_DISPATCHED',
  MANUAL_GITHUB_BUILD_FAILED: 'MANUAL_GITHUB_BUILD_FAILED',
  MANUAL_GITHUB_BUILD_SUCCESS: 'MANUAL_GITHUB_BUILD_SUCCESS',
  GITHUB_BUILD_TRIGGER_BUTTON_CLICKED: 'GITHUB_BUILD_TRIGGER_BUTTON_CLICKED',
  GITHUB_BUILD_TRIGGER_CREATED: 'GITHUB_BUILD_TRIGGER_CREATED',
  GITHUB_BUILD_TRIGGER_DELETED: 'GITHUB_BUILD_TRIGGER_DELETED',
  GITHUB_BUILD_TRIGGER_STATE_TOGGLED: 'GITHUB_BUILD_TRIGGER_STATE_TOGGLED',
  GITHUB_BUILD_TRIGGER_EDITED: 'GITHUB_BUILD_TRIGGER_EDITED',
};

export const EASShoutoutActions = {
  SETTINGS_MODAL_CLICK: 'settings modal click',
  SIGNUP_AND_LEARN_MORE: 'signup and learn more',
  ONBOARDING_LINK_CLICK: 'onboarding link click',
  SHOUTOUT_LINK_CLICK: 'shutout link click',
};

export const EASSubscriptionFlowSteps = {
  CLICK_PRICING_GET_STARTED_BUTTON: 'click pricing get started button',
  VIEW_SETTINGS_SUBSCRIPTIONS_PAGE: 'view settings subscriptions page',
  CLICK_CHANGE_PLAN_BUTTON: 'click change plan button',
  VIEW_CHANGE_PLAN_SCREEN: 'view change plan screen',
  CLICK_CHECKOUT_BUTTON: 'click checkout button',
  CLICK_UPGRADE_BUTTON: 'click upgrade button',
  CLICK_CANCEL_DOWNGRADE: 'click cancel downgrade button',
  CLICK_MANAGE_BILLING_BUTTON: 'click manage billing button',
  VIEW_CHECKOUT_RETURN_SCREEN: 'view checkout return screen',
  CLICK_BILLING_UPGRADE_CTA: 'click billing upgrade cta',
  CLICK_CONTACT_PAGE_UPGRADE_CTA: 'click contact page upgrade cta',
  CLICK_PLAN_OVERAGE_WARNING_BUILD_CTA: 'click plan overage warning build cta',
  CLICK_PLAN_OVERAGE_WARNING_UPDATE_CTA: 'click plan overage warning update cta',
  CLICK_PLAN_OVERAGE_WARNING_DASHBOARD_CTA: 'click plan overage warning dashboard cta',
  CLICK_FREE_PLAN_BUTTON: 'click free plan button',
  CLICK_ON_DEMAND_PLAN_BUTTON: 'click on-demand plan button',
  CLICK_PRODUCTION_PLAN_BUTTON: 'click production plan button',
  CLICK_ENTERPRISE_PLAN_BUTTON: 'click enterprise plan button',
  CLICK_CHOOSE_ACCOUNT_CONTINUE: 'click choose account continue',
};

export const BuildAnnotationEvents = {
  SHOW_ANNOTATION: 'SHOW_ANNOTATION',
  VIEW_ANNOTATION: 'VIEW_ANNOTATION',
  CLICK_ANNOTATION_LINK: 'CLICK_ANNOTATION_LINK',
};

export type EASSubscriptionFlowAnalyticsData = {
  userId: string | null;
  accountId: string | null;
  isOwner: boolean | null;
  currentPlan: string | null;
  currentAddOns: AddonDetails[] | null;
  status: string | null;
  isDowngrading: boolean | null;
  willCancel: boolean | null;
  // todo - add source
};

export const ProjectCredentialsActions = {
  CLICK_ANDROID_GET_STARTED_BUTTON: 'click android get started button',
  CLICK_ANDROID_NEW_BUTTON: 'click android new button',

  CLICK_IOS_GET_STARTED_BUTTON: 'click ios get started button',
  CLICK_IOS_NEW_BUTTON: 'click ios new button',

  ANDROID_WIZARD_SUCCESS: 'android wizard success',
  ANDROID_WIZARD_FAIL: 'android wizard fail',

  IOS_WIZARD_SUCCESS: 'ios wizard success',
  IOS_WIZARD_FAIL: 'ios wizard fail',

  ASC_API_KEY_UPDATE_SUCCESS: 'asc api key update success',
  ASC_API_KEY_UPDATE_FAIL: 'asc api key update fail',
  ASC_API_KEY_DELETED: 'asc api key deleted',

  DIST_CERT_UPDATE_SUCCESS: 'dist cert update success',
  DIST_CERT_UPDATE_FAIL: 'dist cert update fail',
  DIST_CERT_DELETED: 'dist cert deleted',
  DIST_CERT_DOWNLOADED: 'dist cert downloaded',

  PROV_PROFILE_UPDATE_SUCCESS: 'prov profile update success',
  PROV_PROFILE_UPDATE_FAIL: 'prov profile update fail',
  PROV_PROFILE_DELETED: 'prov profile deleted',
  PROV_PROFILE_DOWNLOADED: 'prov profile downloaded',

  PUSH_KEY_UPDATE_SUCCESS: 'push key update success',
  PUSH_KEY_UPDATE_FAIL: 'push key update fail',
  PUSH_KEY_DELETED: 'push key deleted',
  PUSH_KEY_DOWNLOADED: 'push key downloaded',

  KEYSTORE_UPDATE_SUCCESS: 'keystore update success',
  KEYSTORE_UPDATE_FAIL: 'keystore update fail',
  KEYSTORE_DELETED: 'keystore deleted',
  KEYSTORE_DOWNLOADED: 'keystore downloaded',

  GOOGLE_SERVICE_ACCOUNT_KEY_UPDATE_SUCCESS: 'google service account key update success',
  GOOGLE_SERVICE_ACCOUNT_KEY_UPDATE_FAIL: 'google service account key update fail',
  GOOGLE_SERVICE_ACCOUNT_KEY_DELETED: 'google service account key deleted',

  FCM_SERVER_KEY_UPDATE_SUCCESS: 'fcm server key update success',
  FCM_SERVER_KEY_UPDATE_FAIL: 'fcm server key update fail',
  FCM_SERVER_KEY_DELETED: 'fcm server key deleted',
  FCM_SERVER_KEY_DOWNLOADED: 'fcm server key downloaded',

  ANDROID_BUILD_CREDENTIALS_CREATE_SUCCESS: 'android build credentials create success',
  ANDROID_BUILD_CREDENTIALS_CREATE_FAIL: 'android build credentials create fail',
  ANDROID_BUILD_CREDENTIALS_UPDATE_SUCCESS: 'android build credentials update success',
  ANDROID_BUILD_CREDENTIALS_UPDATE_FAIL: 'android build credentials update fail',
  ANDROID_BUILD_CREDENTIALS_DELETED: 'android build credentials deleted',

  IOS_DIST_TYPE_CREATE_SUCCESS: 'ios dist type create success',
  IOS_DIST_TYPE_CREATE_FAIL: 'ios dist type create fail',
};

export const googleAnalyticsEvents = {
  SIGN_UP: 'sign_up',
};

export const googleAdwordsEventIds = {
  SIGN_UP_FORM_SUBMISSION: 'euU1COvq65UYEIHWvLMp',
  SIGN_UP_PAGE_VIEW: 'FSRTCN3u9pUYEIHWvLMp',
  BILLING_PAGE_FLOW: 'DdSACPHq65UYEIHWvLMp',
  STRIPE_CHECKOUT: 'Oit0CO7q65UYEIHWvLMp',
};

export enum OnboardingEvents {
  ONBOARDING_HELLO_PAGE_VIEWED = 'onboarding hello page viewed (flow start)',
  ONBOARDING_INDEX_REDIRECT_PAGE_VIEWED = 'onboarding index redirect page viewed',
  ONBOARDING_GITHUB_PAGE_VIEWED = 'onboarding github page viewed',
  ONBOARDING_SETUP_PROJECT_PAGE_VIEWED = 'onboarding setup project page viewed',
  ONBOARDING_SELECT_DEVELOPMENT_ENVIRONMENT_PAGE_VIEWED = 'onboarding select development environment page viewed',
  ONBOARDING_SET_UP_PROJECT_ON_YOUR_MACHINE_PAGE_VIEWED = 'onboarding set up project on your machine page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_ANDROID_DEVICE_EXPO_GO_PAGE_VIEWED = 'onboarding setup development environment (android/device/expo go) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_ANDROID_SIMULATOR_EXPO_GO_PAGE_VIEWED = 'onboarding setup development environment (android/simulator/expo go) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_ANDROID_DEVICE_DEVELOPMENT_BUILD_PAGE_VIEWED = 'onboarding setup development environment (android/device/development build) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_ANDROID_SIMULATOR_DEVELOPMENT_BUILD_PAGE_VIEWED = 'onboarding setup development environment (android/simulator/development build) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_IOS_DEVICE_EXPO_GO_PAGE_VIEWED = 'onboarding setup development environment (ios/device/expo go) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_IOS_SIMULATOR_EXPO_GO_PAGE_VIEWED = 'onboarding setup development environment (ios/simulator/expo go) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_IOS_DEVICE_DEVELOPMENT_BUILD_PAGE_VIEWED = 'onboarding setup development environment (ios/device/development build) page viewed',
  ONBOARDING_SETUP_DEVELOPMENT_ENVIRONMENT_IOS_SIMULATOR_DEVELOPMENT_BUILD_PAGE_VIEWED = 'onboarding setup development environment (ios/simulator/development build) page viewed',
  ONBOARDING_WELCOME_PAGE_VIEWED = 'onboarding welcome page viewed (flow end)',
}

export const events = {
  HOMEPAGE_GET_STARTED_CLICKED: 'HOMEPAGE_GET_STARTED_CLICKED',
  // we changed the homepage get started button to link to docs May 22-ish: https://github.com/expo/universe/pull/12396
  HOMEPAGE_GET_STARTED_CLICKED_LINKED_TO_DOCS: 'HOMEPAGE_GET_STARTED_CLICKED_LINKED_TO_DOCS',
  HOMEPAGE_READ_STORIES_CLICKED: 'HOMEPAGE_READ_STORIES_CLICKED',
  USER_DELETED_ACCOUNT: 'USER_DELETED_ACCOUNT',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_CREATED_ACCOUNT: 'USER_CREATED_ACCOUNT',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_UPDATED_SETTINGS: 'USER_UPDATED_SETTINGS',
  USER_UPDATED_PRIVACY: 'USER_UPDATED_PRIVACY',
  USER_SEARCHED: 'USER_SEARCHED',
  USER_UPDATED_INTERNAL_DISTRIBUTION_PRIVACY: 'USER_UPDATED_INTERNAL_DISTRIBUTION_PRIVACY',
  USER_PAGE_VIEWED: 'USER_PAGE_VIEWED', // { url: <string> },
  USER_CLICKED_CTA: 'USER_CLICKED_CTA', // { name: <string>, position: <string>}
  USER_DELETED_LOGIN_SESSIONS: 'USER_DELETED_LOGIN_SESSIONS',
  EAS_SUBSCRIPTION_FLOW: 'EAS_SUBSCRIPTION_FLOW',
  EAS_SHOUTOUT: 'EAS_SHOUTOUT',
  PROJECT_CREDENTIALS_UI: 'PROJECT_CREDENTIALS_UI',
  STAR_US_ON_GITHUB_CLICKED: 'STAR_US_ON_GITHUB_CLICKED',
  ...dashboardEvents,
  ...buildEvents,
  ...orgConversionEvents,
  ...accessTokenEvents,
  ...attributionEvents,
  ...twoFactorAuthEvents,
  ...GitHubUIEvents,
  ...BuildAnnotationEvents,
};

export const identify = (id: string, traits: { username: string; email?: string | null }) => {
  window?.rudderanalytics?.identify(id, traits);
};

// Identify user with a persistent anonymous id
export const identifyAnonymous = () => {
  window?.rudderanalytics?.identify();
};

export const useTrackOnce = () => {
  const hasTracked = useRef({} as { [eventId: string]: boolean });

  const trackOnce = (event: string, properties?: Record<string, any>) => {
    if (!hasTracked.current[event + JSON.stringify(properties)]) {
      track(event, properties);
      hasTracked.current = { ...hasTracked.current, [event + JSON.stringify(properties)]: true };
    }
  };

  return trackOnce;
};

export const track = (event: string, properties?: Record<string, any>) => {
  window?.rudderanalytics?.track(event, {
    ...properties,
    isMobile: isMobileBrowser(),
  });
};

export const googleAnalyticsId = 'G-FS5QCP5HZ7';
export const rudderStackWriteKey = process.env.RUDDERSTACK_WRITE_KEY ?? '';
export const rudderStackDataPlaneURL = process.env.RUDDERSTACK_DATA_PLANE_URL ?? '';
export const googleAdWordsTagGlobalTagId = 'AW-11113802497';

export function getGTagScriptTags({ currentDateAsString }: { currentDateAsString: string }) {
  return (
    <>
      <Script
        id="gtag-script"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date('${currentDateAsString}'));
        gtag('config', '${googleAnalyticsId}', { 'transport_type': 'beacon', 'anonymize_ip': true });
        gtag('config', '${googleAdWordsTagGlobalTagId}');
      `,
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
    </>
  );
}

// Creates an array to store the events until the analytics object is ready.
// Stores a list of methods to replay them when the analytics object is ready. These methods include:
export function getRudderStackScriptTags() {
  return (
    <Script
      id="rudderstack-script"
      type="text/javascript"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){e.push([t].concat(Array.prototype.slice.call(arguments)))}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
          e.load('${rudderStackWriteKey}','${rudderStackDataPlaneURL}'),
          e.page()}();`,
      }}
    />
  );
}

// Allows creating an event that can be accessed in Google Analytics 4

export const gtagEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: Gtag.EventNames | string;
  category?: Gtag.EventParams | string;
  label: Gtag.EventParams | string;
  value?: Gtag.EventParams | number;
}) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const googleAdwordsEvent = ({
  trackingEventId,
  googleAdsId,
}: {
  trackingEventId: string;
  googleAdsId: string;
}) => {
  if (trackingEventId === googleAdwordsEventIds.STRIPE_CHECKOUT) {
    window?.gtag?.('event', 'conversion', {
      send_to: `${googleAdsId}/${trackingEventId}`,
      value: 1.0,
      currency: 'USD',
      transaction_id: '',
    });
  } else {
    window?.gtag?.('event', 'conversion', {
      send_to: `${googleAdsId}/${trackingEventId}`,
    });
  }
};
