import {
  NameValidationErrorCode,
  validateAccountName,
  minimumAccountNameLength,
  maximumAccountNameLength,
} from '@expo/account-names';
import isEmpty from 'lodash/isEmpty';

import * as Strings from '~/common/strings';

const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\da-z-]+\.)+[a-z]{2,}))$/i;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_CLIENT_IDENTIFIER_LENGTH = 512;
export const MAX_CLIENT_SECRET_LENGTH = 512;
export const MAX_ISSUER_LENGTH = 1024;

export const messages = {
  DEFAULT_FORM_ERROR: 'Something went wrong. Please try again.',
  BIO_TOO_LONG: 'Your bio is too long.',
  USERNAME_SAVE_ERROR: 'We could not save this username, try another one.',
  USERNAME_TAKEN: 'This user already exists, please log in instead.',
  USERNAME_NO_EXIST: 'Please provide us with a username.',
  USERNAME_TOO_SHORT: `Your username must be at least ${minimumAccountNameLength} characters.`,
  USERNAME_NO_SPECIAL:
    'Username may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen.',
  USERNAME_TOO_LONG: `Your username must be under ${maximumAccountNameLength} characters.`,
  PASSWORD_NO_EXIST: 'Please provide us with a password.',
  PASSWORD_TOO_SHORT: `Your password must be at least ${MIN_PASSWORD_LENGTH} characters.`,
  EMAIL_SAVE_ERROR: 'We could not save this email, try another one.',
  CONFIRM_PASSWORD_MISSING: 'Please provide and confirm a new password.',
  CONFIRM_PASSWORD_WRONG: 'Your confirmation password does not match.',
  JSON_INVALID: 'This is not a valid JSON string.',
  DEFAULT_OUR_FAULT: 'Something on our end broke! We are so sorry about this. Try again later',
  CRITICAL_AUTH_ERROR:
    'We have broken something with our authentication setup, we will resolve this soon.',
  INVALID_USERNAME_PASSWORD: 'Wrong username or password.',
  MUST_BE_LOGGED_IN_FOR_SUGGESTED: 'Must be logged in to find suggested accounts.',
  USER_IS_BLOCKED:
    'Your user account is currently disabled. Please go to expo.dev/contact for assistance.',
  CANT_SEND_EMAIL: 'We were unable to send you an email, please try again.',
  INVALID_TEMP_AUTH:
    'Your temporary authorization code is not valid anymore. Please try resetting your password again.',
  INVALID_EMAIL_VERIFICATION_CODE:
    'Your email verification code is not valid. Please go to expo.dev/contact for assistance',
  DELETE_ACCOUNT_STRING: 'I understand and I want to delete my account',
  EMAIL_NOT_VALID: 'Your email address is not valid.',
  ORGANIZATION_NAME_NO_EXIST: 'Organization name is a required field',
  ORGANIZATION_NAME_TOO_SHORT: `Your organization name must be at least ${minimumAccountNameLength} characters.`,
  ORGANIZATION_NAME_NO_SPECIAL:
    'Organization name may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen.',
  ORGANIZATION_NAME_TOO_LONG: `Your organization name must be under ${maximumAccountNameLength} characters.`,
  ISSUER_NO_EXIST: 'Please provide us with an issuer.',
  ISSUER_TOO_LONG: `Issuer must be under ${MAX_ISSUER_LENGTH} characters`,
  ISSUER_INVALID: 'Issuer must be a valid URL starting with https://',
  CLIENT_IDENTIFIER_NO_EXIST: 'Please provide us with a client identifier.',
  CLIENT_IDENTIFIER_TOO_LONG: `Your client secret must be under ${MAX_CLIENT_IDENTIFIER_LENGTH} characters.`,
  CLIENT_SECRET_NO_EXIST: 'Please provide us with a client secret.',
  CLIENT_SECRET_TOO_LONG: `Your client secret must be under ${MAX_CLIENT_SECRET_LENGTH} characters.`,
};

export const username = (username: string) => {
  const validationResult = validateAccountName(username);

  if (validationResult === NameValidationErrorCode.EMPTY) {
    return messages.USERNAME_NO_EXIST;
  }

  if (validationResult === NameValidationErrorCode.INVALID_CHARACTERS) {
    return messages.USERNAME_NO_SPECIAL;
  }

  if (validationResult === NameValidationErrorCode.TOO_SHORT) {
    return messages.USERNAME_TOO_SHORT;
  }

  if (validationResult === NameValidationErrorCode.TOO_LONG) {
    return messages.USERNAME_TOO_LONG;
  }

  return undefined;
};

export const organizationName = (organizationName: string) => {
  const validationResult = validateAccountName(organizationName);

  if (validationResult === NameValidationErrorCode.EMPTY) {
    return messages.ORGANIZATION_NAME_NO_EXIST;
  }

  if (validationResult === NameValidationErrorCode.INVALID_CHARACTERS) {
    return messages.ORGANIZATION_NAME_NO_SPECIAL;
  }

  if (validationResult === NameValidationErrorCode.TOO_SHORT) {
    return messages.ORGANIZATION_NAME_TOO_SHORT;
  }

  if (validationResult === NameValidationErrorCode.TOO_LONG) {
    return messages.ORGANIZATION_NAME_TOO_LONG;
  }

  return undefined;
};

export const newPassword = (password: string) => {
  if (Strings.isEmptyOrNull(password)) {
    return messages.PASSWORD_NO_EXIST;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return messages.PASSWORD_TOO_SHORT;
  }

  return undefined;
};

export const existingPassword = (password: string) => {
  if (Strings.isEmptyOrNull(password)) {
    return messages.PASSWORD_NO_EXIST;
  }

  return undefined;
};

export const emptyField = (value: any, fieldName: string) => {
  if (isEmpty(value)) {
    return `Please provide us with a ${fieldName}.`;
  }
  return undefined;
};

export const isEmail = (email?: string) => {
  if (!email) {
    return false;
  }

  return Boolean(email.match('@')) || false;
};

export const email = (email: string) => {
  if (!EMAIL_REGEX.test(email)) {
    return messages.EMAIL_NOT_VALID;
  }

  return undefined;
};

export const json = (json: string) => {
  try {
    JSON.parse(json);
  } catch {
    return messages.JSON_INVALID;
  }

  return null;
};

function isVowel(ch: string) {
  const vowels = new Set<string>(['a', 'e', 'i', 'o', 'u']);
  return vowels.has(ch.toLowerCase());
}

export const emptySelection = (value: any, fieldName: string) => {
  if (isEmpty(value)) {
    if (isVowel(fieldName.charAt(0))) {
      return `Please select an ${fieldName}.`;
    }
    return `Please select a ${fieldName}.`;
  }
  return undefined;
};

export const issuer = (issuer: string) => {
  if (isEmpty(issuer)) {
    return messages.ISSUER_NO_EXIST;
  }
  if (issuer.length > MAX_ISSUER_LENGTH) {
    return messages.ISSUER_TOO_LONG;
  }
  try {
    const issuerUrl = new URL(issuer);
    if (!issuerUrl.protocol.startsWith('https')) {
      return messages.ISSUER_INVALID;
    }
  } catch {
    return messages.ISSUER_INVALID;
  }
  return undefined;
};

export const clientIdentifier = (clientIdentifier: string) => {
  if (isEmpty(clientIdentifier)) {
    return messages.CLIENT_IDENTIFIER_NO_EXIST;
  }
  if (clientIdentifier.length > MAX_CLIENT_IDENTIFIER_LENGTH) {
    return messages.CLIENT_IDENTIFIER_TOO_LONG;
  }
  return undefined;
};

export const clientSecret = (clientSecret: string) => {
  if (isEmpty(clientSecret)) {
    return messages.CLIENT_SECRET_NO_EXIST;
  }
  if (clientSecret.length > MAX_CLIENT_SECRET_LENGTH) {
    return messages.CLIENT_SECRET_TOO_LONG;
  }
  return undefined;
};
