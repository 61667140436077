import { Themes } from '@expo/styleguide';

export function getMetaThemeColor(themeName: Themes) {
  if (themeName === 'dark') {
    return <meta name="theme-color" content="#151718" />;
  } else if (themeName === 'light') {
    return <meta name="theme-color" content="#fff" />;
  } else if (themeName === 'auto') {
    return (
      <>
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#fff" />
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#151718" />
      </>
    );
  } else {
    return null;
  }
}
