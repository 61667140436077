export enum FeatureGateKey {
  // for tests
  TEST = 'test',
  STRIPE_KILLSWITCH = 'stripe-killswitch',
  EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS = 'eas-insights-enable-deployment-insights',
  RELAY_PAGINATED_COLLECTIONS = 'relay-paginated-collections',
  SEARCH_DROPDOWN = 'search-dropdown',
  ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE = 'enable-notification-fcm-v1-credentials-feature-gate',
  ENABLE_NOTIFICATIONS_USAGE_UI = 'enable-notifications-usage-ui',
  PROJECT_CONTENT_BROWSER = 'project-content-browser',
  VIEW_AUDIT_LOG = 'view-audit-log',
  CREATE_AUDIT_LOG = 'create-audit-log',
  ENABLE_RESOURCE_CLASS_EXPERIMENT = 'enable-resource-class-experiment',
  SERVERLESS_DEPLOYMENTS = 'serverless-deployments',
  USER_AND_ACCOUNT_BACKGROUND_DELETION = 'user-and-account-background-deletion',
  ENVIRONMENT_VARIABLES = 'environment-variables',
}
