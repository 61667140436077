import {
  OrganizationSettingsQuery,
  Permission,
  UserPermissionDataFragment,
} from '~/graphql/types.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';

type AccountRequiredShape = {
  ownerUserActor?: { username: string } | null;
  viewerUserPermission: UserPermissionDataFragment;
};

export function isOwnerOrAdminOrImplicitOwner(
  account: AccountRequiredShape,
  currentUserUsername: string
) {
  const isAccountOwner = account.ownerUserActor?.username === currentUserUsername;
  const hasOwnerPermission = viewerHasPermissionOnAccount(account, Permission.Own);
  const hasAdminPermission = viewerHasPermissionOnAccount(account, Permission.Admin);

  return isAccountOwner || hasOwnerPermission || hasAdminPermission;
}

export function isDeveloperOrImplicitOwner(
  account: AccountRequiredShape,
  currentUserUsername: string
) {
  const isAccountOwner = account.ownerUserActor?.username === currentUserUsername;
  const hasOwnerPermission = viewerHasPermissionOnAccount(account, Permission.Own);
  const hasAdminPermission = viewerHasPermissionOnAccount(account, Permission.Admin);
  const hasPublishPermission = viewerHasPermissionOnAccount(account, Permission.Publish);

  return isAccountOwner || hasOwnerPermission || hasAdminPermission || hasPublishPermission;
}

export function viewerHasPermissionOnAccount(
  account: AccountRequiredShape,
  permission: Permission
) {
  const viewerUserPermission = account.viewerUserPermission;
  if (!viewerUserPermission) {
    return false;
  }

  return viewerUserPermission.permissions.includes(permission);
}

export function isOwnerOrImplicitOwner(account: AccountRequiredShape, name: string) {
  const isAccountOwner = account.ownerUserActor?.username === name;
  const hasOwnerPermission = viewerHasPermissionOnAccount(account, Permission.Own);

  return isAccountOwner || hasOwnerPermission;
}

export function hasOtherHumanMembers(account: OrganizationSettingsQuery['account']['byName']) {
  return (account.users ?? [])?.filter((member) => member?.userActor).length > 1;
}

export function isBillingPageDisabled(
  currentUser: LoggedInProps['currentUser'],
  accountName: string
) {
  const account = currentUser?.accounts?.find((account) => account.name === accountName);
  const isPersonalAccountOfSSOUser = account?.ownerUserActor?.__typename === 'SSOUser';

  return isPersonalAccountOfSSOUser
    ? true
    : !currentUser.isExpoAdmin &&
        (!account || !isOwnerOrAdminOrImplicitOwner(account, currentUser?.username));
}

export function isEnvironmentVariablesPageDisabled(
  currentUser: LoggedInProps['currentUser'],
  noPermissionData: boolean,
  account?: AccountRequiredShape
) {
  return (
    !currentUser?.isExpoAdmin &&
    (noPermissionData || !account || !isDeveloperOrImplicitOwner(account, currentUser?.username))
  );
}
