import slugify from 'slugify';

import * as Analytics from '~/common/analytics';
import {
  standardOffersPlanIds,
  stripeCheckoutAnnualOffers,
  stripeCheckoutOffers,
} from '~/common/plans';
import { AccountsDataFragment } from '~/graphql/fragments/AccountsData.fragment.generated';
import { IosDistributionType, UserActor } from '~/graphql/types.generated';
import { ProfileUser } from '~/scenes/ProfileScene/types';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { AccountProp } from '~/ui/components/AvatarLabel/types';
import { InlineLink } from '~/ui/components/InlineLink';

type Plan = 'free' | 'priority' | 'production' | 'enterprise';

// https://css-tricks.com/converting-color-spaces-in-javascript/
const hexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const hexToRGBA = (hex: string, alpha: number) => {
  const [r, g, b] = hexToRGB(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const hexToAccessibleHSLA = (hex: string, alpha: number) => {
  let [r, g, b] = hexToRGB(hex);

  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  // No difference
  if (delta === 0) {
    h = 0;
  }
  // Red is max
  else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  }
  // Green is max
  else if (cmax === g) {
    h = (b - r) / delta + 2;
  }
  // Blue is max
  else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) {
    h += 360;
  }

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100.
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  if (Number.isNaN(l)) {
    return 'hsla(0,0%,0%,0.5)';
  }

  if (l > 75) {
    l = 100 - l;
  }

  return `hsla(${h},${s}%,${l}%,${alpha})`;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function getReadableIosDistributionType(distType: IosDistributionType) {
  switch (distType) {
    case IosDistributionType.AppStore:
      return 'App Store';
    case IosDistributionType.AdHoc:
      return 'Ad-hoc';
    case IosDistributionType.Development:
      return 'Development';
    case IosDistributionType.Enterprise:
      return 'Enterprise';
  }
}

export function userHasPrioritySubscription(data: LoggedInProps['currentUser']) {
  const plans = getUserSubscriptionPlans(data);

  return (['priority', 'production', 'enterprise'] as Plan[]).some((plan) => plans.includes(plan));
}

const activeSubscriptionPlanStatuses = new Set(['active', 'trailing', 'past_due']);

const offerPlanIdToPricingInformationPlanId: Record<string, Plan> = {
  [standardOffersPlanIds.DEFAULT]: 'priority',
  [standardOffersPlanIds.YC_DEALS]: 'priority',
  [standardOffersPlanIds.YEARLY_SUB]: 'priority',
  [stripeCheckoutOffers.FREE]: 'free',
  [stripeCheckoutOffers.PRODUCTION]: 'production',
  [stripeCheckoutOffers.ENTERPRISE]: 'enterprise',
  [stripeCheckoutAnnualOffers.FREE]: 'free',
  [stripeCheckoutAnnualOffers.PRODUCTION]: 'production',
  [stripeCheckoutAnnualOffers.ENTERPRISE]: 'enterprise',
};

export function getUserSubscriptionPlans(data: LoggedInProps['currentUser']) {
  return Array.from(
    new Set(
      data.accounts
        .filter((account) => activeSubscriptionPlanStatuses.has(account.subscription?.status ?? ''))
        .map(
          (account) =>
            offerPlanIdToPricingInformationPlanId[
              account.subscription?.planId ?? stripeCheckoutOffers.FREE
            ] ?? 'free'
        )
    )
  );
}

export function getSubscriptionPlanForAccount(
  accountName: string,
  data: LoggedInProps['currentUser']
) {
  const accountData = data.accounts.filter((account) => account.name === accountName)[0];

  // note(simek): we return `null` for Expo Admins visiting unowned accounts
  return accountData
    ? offerPlanIdToPricingInformationPlanId[
        accountData?.subscription?.planId ?? stripeCheckoutOffers.FREE
      ] ?? 'legacy'
    : null;
}

export function accountsHaveAccessToPrioritySubscription(accounts?: AccountsDataFragment[]) {
  if (!accounts) {
    return false;
  }

  return (
    accounts.some((account) => {
      const subscription = account.subscription;
      return (
        subscription?.status === 'active' ||
        subscription?.status === 'trialing' ||
        subscription?.status === 'past_due'
      );
    }) ?? false
  );
}

export function categorizeAccounts(
  currentUser: LoggedInProps['currentUser'],
  // TODO(simek): remove, refactor all usages and reduce passed props
  accounts: LoggedInProps['currentUser']['accounts'] = currentUser.accounts
): {
  personalAccount?: LoggedInProps['currentUser']['accounts'][0];
  userAccounts: LoggedInProps['currentUser']['accounts'];
  organizationAccounts: LoggedInProps['currentUser']['accounts'];
} {
  const personalAccount = accounts.find((account) => account.ownerUserActor?.id === currentUser.id);
  const userAccounts = accounts?.filter(
    (account) => account.ownerUserActor && account.name !== currentUser.username
  );
  const organizationAccounts = accounts?.filter(
    (account) => account.name !== currentUser.username && !account.ownerUserActor
  );

  return { personalAccount, userAccounts, organizationAccounts };
}

export function isUserAccount(accountName: string, currentUser: LoggedInProps['currentUser']) {
  return Boolean(
    currentUser?.accounts?.find(
      (account) => account?.name === accountName && account.name === currentUser?.username
    )?.ownerUserActor
  );
}

export function isTeamAccount(accountName: string, currentUser: LoggedInProps['currentUser']) {
  return Boolean(
    currentUser?.accounts?.find(
      (account) => account.name === accountName && account.name !== currentUser?.username
    )?.ownerUserActor
  );
}

export function getShortId(id: string = '') {
  return id.split('-')[0];
}

export function getGitCommitValue(
  gitCommitHash?: string | null,
  githubRepositoryUrl?: string | null,
  isGitWorkingTreeDirty?: boolean | null
) {
  if (!gitCommitHash) {
    return;
  }

  const gitCommitText = `${gitCommitHash.slice(0, 7)}${isGitWorkingTreeDirty ? '*' : ''}`;

  if (githubRepositoryUrl) {
    return (
      <InlineLink
        onClick={() => {
          Analytics.track(Analytics.events.GITHUB_COMMIT_LINK_CLICKED);
        }}
        className="border-b-0"
        size="span"
        type="external"
        href={`${githubRepositoryUrl}/commit/${gitCommitHash}`}
        text={gitCommitText}
      />
    );
  }

  return gitCommitText;
}

export function truncateMultilineMessage(message: string) {
  const lines = message.trim().split('\n');
  if (lines.length > 1) {
    return `${lines[0]}…`;
  }
  return message;
}

export function getGenericErrorMessage(cta: string = 'Refresh to try again.') {
  return `Either you don't have permissions to view this page, this object doesn't exist, or our code broke.${
    cta.length > 0 ? ` ${cta}` : ''
  }`;
}

export function isSHA1Hash(str: string) {
  // Regular expression pattern for SHA-1 hash
  const sha1Regex = /^[\dA-Fa-f]{40}$/;

  // Test if the string matches the SHA-1 pattern
  return sha1Regex.test(str);
}

export function getUserDisplayName(
  user: LoggedInProps['currentUser'] | ProfileUser | UserActor | AccountProp
) {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.firstName) {
    return user.firstName;
  } else if (user.lastName) {
    return user.lastName;
  } else if ('fullName' in user && user.fullName) {
    return user.fullName;
  } else if ('name' in user && user.name) {
    return user.name;
  } else if (user.username) {
    return user.username;
  }
  return 'Unknown';
}

export function generateSlug(value: string) {
  return slugify(value, { trim: false, strict: true }).toLowerCase();
}

export const IS_VALID_SLUG_REGEX = /^[\w\\-]+$/; // from xdl-schemas slug

/*
 * Converts the slug from app configuration to a string that's a valid URI scheme.
 * From RFC3986 Section 3.1.
 * scheme      = ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
 *
 * Our url-scheme is later prepended with `exp+` to avoid leading digits and to distinguish from user-defined schemes.
 */
export const CONVERT_SLUG_TO_URL_SCHEME_REGEX = /[^\d+.A-Za-z-]/g;

export function convertToArray(selectedFilters: string | null) {
  if (!selectedFilters) {
    return null;
  }
  return selectedFilters.split(',');
}

export function getProjectURL(accountName: string, projectName: string, path?: string) {
  const baseProjectURL = `/accounts/${encodeURIComponent(accountName)}/projects/${encodeURIComponent(projectName)}`;

  if (path) {
    return path.startsWith('/') ? baseProjectURL + path : `${baseProjectURL}/${path}`;
  }

  return baseProjectURL;
}
