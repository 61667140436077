import * as Analytics from '~/common/analytics';

export function getProductLinks(
  websiteUrl = '',
  snackUrl: undefined | string = process.env.SNACK_SERVER_URL
) {
  return [
    {
      href: 'https://www.github.com/expo/expo',
      onClick: () => Analytics.track(Analytics.events.STAR_US_ON_GITHUB_CLICKED),
      label: 'Expo on GitHub',
      openInNewTab: true,
    },
    {
      href: 'https://github.com/expo/expo/tree/main/packages/%40expo/cli',
      label: 'Expo CLI on GitHub',
      openInNewTab: true,
    },
    {
      href: `${websiteUrl}/eas`,
      label: 'EAS',
    },
    {
      href: 'https://github.com/expo/eas-cli',
      label: 'EAS CLI on GitHub',
      openInNewTab: true,
    },
    {
      href: `${snackUrl ?? process.env.SNACK_SERVER_URL}`,
      label: 'Snack',
      openInNewTab: true,
    },
    {
      href: `${websiteUrl}/go`,
      label: 'Expo Go',
    },
    {
      href: `${websiteUrl}/orbit`,
      label: 'Expo Orbit',
    },
    {
      href: 'https://github.com/expo/expo',
      label: 'Star Us on GitHub',
      openInNewTab: true,
    },
  ];
}

export function getResourceLinks(websiteUrl = '') {
  return [
    {
      href: 'https://docs.expo.dev/',
      label: 'Docs',
      openInNewTab: true,
    },
    {
      href: 'https://jobs.expo.dev',
      label: 'Job Board',
      openInNewTab: true,
    },
    {
      href: 'https://chat.expo.dev',
      label: 'Join Discord',
      openInNewTab: true,
    },
    {
      href: `${websiteUrl}/changelog`,
      label: 'Changelog',
    },
    {
      href: `${websiteUrl}/support`,
      label: 'Support',
    },
  ];
}

export function getCompanyLinks(websiteUrl = '') {
  return [
    {
      href: `${websiteUrl}/home`,
      label: 'Home',
    },
    {
      href: `${websiteUrl}/pricing`,
      label: 'Pricing',
    },
    {
      href: `${websiteUrl}/about`,
      label: 'About',
    },
    {
      href: `${websiteUrl}/careers`,
      label: 'Work at Expo',
    },
    {
      href: `${websiteUrl}/blog`,
      label: 'Blog',
    },
    {
      href: `${websiteUrl}/mailing-list/signup`,
      label: 'Newsletter',
    },
  ];
}

export function getLegalLinks(websiteUrl = '') {
  return [
    {
      href: `${websiteUrl}/terms`,
      label: 'Terms of service',
    },
    {
      href: `${websiteUrl}/eas/fair-use`,
      label: 'Fair use policy',
    },
    {
      href: `${websiteUrl}/privacy`,
      label: 'Privacy policy',
    },
    {
      href: `${websiteUrl}/privacy-explained`,
      label: 'Privacy explained',
    },
    {
      href: `${websiteUrl}/guidelines`,
      label: 'Guidelines',
    },
  ];
}
