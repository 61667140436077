import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class CreateAuditLogFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.CREATE_AUDIT_LOG;
  }
}

export function useCreateAuditLogFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.CREATE_AUDIT_LOG] ?? defaultGate;
}
