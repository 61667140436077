import { WordMarkLogo } from '@expo/styleguide';
import { DiscordIcon } from '@expo/styleguide-icons/custom/DiscordIcon';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { XLogoIcon } from '@expo/styleguide-icons/custom/XLogoIcon';

import { LinkBase } from '~/ui/components/LinkBase';

import { Button } from '../Button';

const socialLinks = [
  {
    href: 'https://www.github.com/expo/expo',
    title: 'GitHub',
    Icon: GithubIcon,
  },
  {
    href: 'https://www.twitter.com/expo',
    title: 'X',
    Icon: XLogoIcon,
  },
  {
    href: 'https://chat.expo.dev',
    title: 'Discord',
    Icon: DiscordIcon,
  },
];

export function SocialLinks() {
  return (
    <div className="flex items-center">
      <LinkBase prefetch={false} href="/">
        <WordMarkLogo className="icon-lg min-w-[84px]" title="Expo home" />
      </LinkBase>
      <div className="ml-4 mr-2 h-7 w-px bg-palette-gray5" />
      <div className="flex gap-0.5">
        {socialLinks.map(({ Icon, title, href }) => (
          <Button
            prefetch={false}
            theme="quaternary"
            size="xs"
            className="px-1.5"
            href={href}
            title={title}
            openInNewTab
            key={`social-button-${title}`}>
            <Icon title={title} />
          </Button>
        ))}
      </div>
    </div>
  );
}
