import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class ViewAuditLogFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.VIEW_AUDIT_LOG;
  }
}

export function useViewAuditLogFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.VIEW_AUDIT_LOG] ?? defaultGate;
}
