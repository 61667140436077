import { motion, AnimatePresence } from 'framer-motion';
import { Component, ReactNode, createContext, useContext } from 'react';

import { FeedbackTheme, Feedback } from '~/ui/components/Feedback';
import { zIndex } from '~/ui/foundations/spacing';

export const ToastContext = createContext({
  add: (_: Toast) => {},
  remove: () => {},
});

type ToastProviderProps = {
  children: ReactNode;
};

type ToastProviderState = {
  toast?: Toast;
};

export type Toast = {
  message: string;
  theme?: FeedbackTheme;
  activeDurationMilliseconds?: number;
  onClose?: () => void;
};

const ANIMATION_DURATION = 250;

export class ToastProvider extends Component<ToastProviderProps, ToastProviderState> {
  state: ToastProviderState = {
    toast: undefined,
  };

  add = (toastData: Toast) => {
    const { toast } = this.state;

    if (toast) {
      this.remove();
      setTimeout(() => {
        this.setState({ toast: toastData });

        if (toastData.activeDurationMilliseconds) {
          setTimeout(() => {
            this.remove();
            if (toastData.onClose) {
              toastData.onClose();
            }
          }, toastData.activeDurationMilliseconds);
        }
      }, ANIMATION_DURATION);
    } else {
      this.setState({ toast: toastData });

      if (toastData.activeDurationMilliseconds) {
        setTimeout(() => {
          this.remove();
          if (toastData.onClose) {
            toastData.onClose();
          }
        }, toastData.activeDurationMilliseconds);
      }
    }
  };

  remove = () => {
    this.setState({ toast: undefined });
  };

  render() {
    const { children } = this.props;
    const { toast } = this.state;
    const context = {
      add: this.add,
      remove: this.remove,
    };

    return (
      <ToastContext.Provider value={context}>
        {children}
        <AnimatePresence>
          {Boolean(toast) && (
            <motion.div
              style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                zIndex: zIndex.toasts,
                margin: 16,
              }}
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.85 }}
              transition={{ duration: ANIMATION_DURATION / 1000 }}>
              <Feedback {...toast} />
            </motion.div>
          )}
        </AnimatePresence>
      </ToastContext.Provider>
    );
  }
}

export const ToastConsumer = ToastContext.Consumer;

export function useToast() {
  return useContext(ToastContext);
}
