import { Button, mergeClasses } from '@expo/styleguide';
import { Bell03Icon } from '@expo/styleguide-icons/outline/Bell03Icon';
import { Bell04Icon } from '@expo/styleguide-icons/outline/Bell04Icon';
import { useEffect, useRef, useState } from 'react';

import { useUpdateAllNotificationsReadStateMutation } from '~/graphql/mutations/updateAllWebsiteNotificationReadStateAsRead.mutation.generated';
import {
  refetchWebsiteNotificationsPaginatedQuery,
  useWebsiteNotificationsPaginatedQuery,
} from '~/graphql/queries/WebsiteNotificationsPaginated.query.generated';
import { useRefetchOnRefocus } from '~/providers/useRefetchOnRefocus';
import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { NotificationCard } from '~/ui/components/Navigation/Notification/NotificationCard';
import { Popover } from '~/ui/components/Popover';
import { CALLOUT, LABEL } from '~/ui/components/text';

import { BoxContentContainer } from '../../Box/BoxContentContainer';
import { BoxHeader } from '../../Box/BoxHeader';

type Props = {
  popoverClassName?: string;
};

const PAGE_SIZE = 5;

export function NotificationsList({ popoverClassName }: Props) {
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const [mostRecentNotificationId, setMostRecentNotificationId] = useState<string>();
  const [hasNewUnreadNotification, setHasNewUnreadNotification] = useState<boolean>(false);

  const { data, loading, fetchMore, refetch, startPolling, stopPolling } =
    useWebsiteNotificationsPaginatedQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_SIZE,
      },
    });
  const notificationsResponse = data?.meUserActor?.websiteNotificationsPaginated;
  const notificationsData = notificationsResponse?.edges?.map(({ node }) => node) ?? [];
  const hasNotifications = notificationsData.length > 0;

  const refetchWebsiteNotification = refetchWebsiteNotificationsPaginatedQuery({
    first: PAGE_SIZE,
  });
  const [updateAllWebsiteNotificationsAsRead] = useUpdateAllNotificationsReadStateMutation({
    refetchQueries: [refetchWebsiteNotification],
  });

  const fetchMoreDataAsync = async () => {
    await fetchMore({
      variables: {
        first: PAGE_SIZE,
        after: notificationsResponse?.pageInfo.endCursor,
      },
    });
  };

  useEffect(
    function setUnreadNotificationBadgeIfNewNotificationComesIn() {
      if (hasNotifications) {
        const currentNotification = notificationsData[0];
        if (currentNotification.id !== mostRecentNotificationId) {
          setMostRecentNotificationId(currentNotification.id);
        }
        if (!currentNotification.isRead) {
          setHasNewUnreadNotification(true);
        }
      }
    },
    [data]
  );

  useRefetchOnRefocus(refetch);

  useEffect(function pollNotificationsOnMount() {
    startPolling(30000);
    return () => stopPolling();
  }, []);

  const handleScroll = () => {
    const container = popoverContentRef.current;
    if (container === null) {
      return;
    }

    if (!loading && notificationsResponse?.pageInfo.hasNextPage) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      if (scrollTop >= scrollHeight - clientHeight - 100) {
        void fetchMoreDataAsync();
      }
    }
  };

  useEffect(() => {
    const container = popoverContentRef.current;
    if (container === null) {
      return;
    }
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [popoverContentRef.current, data, fetchMore]);

  const onDismissAsync = async () => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
    void updateAllWebsiteNotificationsAsRead();
    setTimeout(() => {
      setHasNewUnreadNotification(false);
    }, 150);
  };

  return (
    <Popover
      trigger={
        <Button
          size="xs"
          theme="quaternary"
          type="button"
          aria-label="Notifications"
          className="relative size-9 justify-center">
          {hasNewUnreadNotification && (
            <div className="absolute right-1 top-1 z-10 size-2 rounded-full bg-palette-blue11" />
          )}
          <Bell03Icon />
        </Button>
      }
      className={mergeClasses(
        'right-3 mt-1.5 !min-w-[380px] !max-w-[380px]',
        'max-sm-gutters:left-[4vw] max-sm-gutters:right-[4vw] max-sm-gutters:!min-w-[92vw] max-sm-gutters:!max-w-[92vw]',
        popoverClassName
      )}>
      <BoxContentContainer size="header" className="px-4 py-3" withDivider>
        <BoxHeader
          size="sm"
          title="Notifications"
          className="max-md-gutters:flex-row max-md-gutters:items-center"
          rightSlot={loading && <ActivityIndicator />}
        />
      </BoxContentContainer>
      <div ref={popoverContentRef} className="no-scrollbar max-h-80 overflow-y-scroll bg-subtle">
        {hasNotifications ? (
          notificationsData.map((notification, index) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              withDivider={!(index === notificationsData.length - 1)}
              onDismiss={onDismissAsync}
            />
          ))
        ) : (
          <div className="flex h-full flex-col items-center justify-center py-12">
            <Bell04Icon className="icon-xl mb-4" />
            <LABEL>No notifications</LABEL>
            <CALLOUT theme="secondary">Enjoy your day!</CALLOUT>
          </div>
        )}
      </div>
    </Popover>
  );
}
