import { ReactElement } from 'react';

import { Button } from '~/ui/components/Button';
import { CALLOUT } from '~/ui/components/text';

import { FeedbackPopoverPage } from './shared';

type Props = {
  label: FeedbackPopoverPage;
  icon: ReactElement;
  onClick: (type: FeedbackPopoverPage) => void;
};

export function PopoverFeedbackOption({ label, icon, onClick }: Props) {
  return (
    <div className="flex justify-center">
      <div className="block">
        <Button
          size="lg"
          theme="secondary"
          onClick={() => onClick(label)}
          className="h-20 w-24 justify-center max-md-gutters:h-16 max-md-gutters:w-20"
          leftSlot={icon}
          testID={`feedback-button-${label}`}
        />
        <CALLOUT className="mt-1 text-center">{label}</CALLOUT>
      </div>
    </div>
  );
}
