import { useRouter } from 'next/compat/router';
import { FormEvent, useState } from 'react';
import * as yup from 'yup';

import { formatParams } from '~/common/formatParams';
import { getSubscriptionPlanForAccount } from '~/common/helpers';
import { useAPIV2Client } from '~/providers/useAPIV2';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Button } from '~/ui/components/Button';
import { Form, getValidatedFormFieldDataAsync } from '~/ui/components/form/Form';
import { FormError } from '~/ui/components/form/FormError';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { TextArea } from '~/ui/components/form/TextArea';
import { ValidationErrors } from '~/ui/components/form/ValidationErrors';

import { PopoverHeaderButtonGroup } from './PopoverHeaderButtonGroup';
import { FeedbackPopoverPage } from './shared';

const MAX_FEEDBACK_LENGTH = 1000;

type Props = {
  page: FeedbackPopoverPage;
  currentUser: LoggedInProps['currentUser'];
  onDismiss: () => void;
  onSetPage: (pageNum: FeedbackPopoverPage) => void;
  hasBackOption?: boolean;
};

const yupSchema = yup.object({
  feedback: yup.string().max(MAX_FEEDBACK_LENGTH).required(`A message is a required`),
});

export function PopoverFeedbackForm({
  page,
  currentUser,
  onDismiss,
  onSetPage,
  hasBackOption,
}: Props) {
  const router = useRouter();
  const apiV2Client = useAPIV2Client();
  const [feedbackError, setFeedbackError] = useState<ValidationErrors>();
  const [formState, setFormState] = useState(FormStates.IDLE);
  const [formError, setFormError] = useState<string>();

  const accountName = formatParams(router?.query).account || currentUser.username;

  async function onSubmitAsync(event: FormEvent) {
    setFormError(undefined);
    setFeedbackError(undefined);
    const [{ feedback }, validationErrors] = await getValidatedFormFieldDataAsync(event, yupSchema);

    if (validationErrors) {
      return setFeedbackError(validationErrors);
    }

    try {
      setFormState(FormStates.LOADING);

      const subscriptionPlan = accountName
        ? getSubscriptionPlanForAccount(accountName, currentUser)
        : undefined;

      const subscriptionPlanWithAccountName = subscriptionPlan
        ? `${subscriptionPlan} (${accountName})`
        : undefined;

      await apiV2Client.sendAuthenticatedApiV2RequestAsync('feedback/send', {
        body: {
          feedbackType: page,
          feedback,
          username: currentUser?.username,
          email: currentUser?.bestContactEmail,
          url: router?.asPath,
          subscriptionPlan: subscriptionPlanWithAccountName,
          userAgent: navigator.userAgent,
          platform: navigator.platform,
        },
      });
      onSetPage(FeedbackPopoverPage.SUCCESS);
    } catch (error) {
      setFormError((error as Error).message);
      setFormState(FormStates.IDLE);
    }
  }

  return (
    <div className="flex flex-1 flex-col">
      <PopoverHeaderButtonGroup
        hasBackOption={hasBackOption}
        previousPage={FeedbackPopoverPage.OPTIONS}
        title="Share feedback"
        onDismiss={onDismiss}
        onSetPage={onSetPage}
        resetFormState={() => {
          setFormError(undefined);
          setFeedbackError(undefined);
        }}
      />
      <Form onSubmit={onSubmitAsync} variant="flat" className="mx-2.5 my-2">
        <FormGroup>
          <TextArea
            autoFocus
            id="feedback"
            characterLimit={MAX_FEEDBACK_LENGTH}
            error={feedbackError?.feedback}
            minRows={7}
            className="text-sm leading-5"
          />
        </FormGroup>
        {formError && <FormError error={formError} />}
        <Button status={formState} className="mt-3 self-end" type="submit">
          Send Feedback
        </Button>
      </Form>
    </div>
  );
}
