// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { GitHubAppInstallationDataFragmentDoc } from './GetGitHubAppInstallations.query.generated';
import { GitHubBuildTriggerDataFragmentDoc } from './GitHubBuildTrigger.fragment.generated';
import { GitHubJobRunTriggerDataFragmentDoc } from './GitHubJobRunTrigger.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGitHubRepoAndSettingsQueryVariables = Types.Exact<{
  fullName: Types.Scalars['String']['input'];
}>;


export type GetGitHubRepoAndSettingsQuery = { __typename?: 'RootQuery', app: { __typename?: 'AppQuery', byFullName: { __typename?: 'App', id: string, githubRepository?: { __typename?: 'GitHubRepository', id: string, createdAt: any, githubRepositoryIdentifier: number, metadata: { __typename?: 'GitHubRepositoryMetadata', githubRepoDescription?: string | null, githubRepoName: string, githubRepoOwnerName: string, githubRepoUrl: string, lastPushed: any, lastUpdated: any, private: boolean }, githubAppInstallation: { __typename?: 'GitHubAppInstallation', id: string, installationIdentifier: number, account: { __typename?: 'Account', id: string, name: string }, metadata: { __typename?: 'GitHubAppInstallationMetadata', githubAccountAvatarUrl?: string | null, githubAccountName?: string | null, installationStatus: Types.GitHubAppInstallationStatus } } } | null, githubRepositorySettings?: { __typename?: 'GitHubRepositorySettings', baseDirectory: string, id: string } | null, githubBuildTriggers: Array<{ __typename?: 'GitHubBuildTrigger', id: string, isActive: boolean, type: Types.GitHubBuildTriggerType, createdAt: any, updatedAt: any, buildProfile: string, lastRunAt?: any | null, platform: Types.AppPlatform, sourcePattern: string, targetPattern?: string | null, autoSubmit: boolean, submitProfile?: string | null, executionBehavior: Types.GitHubBuildTriggerExecutionBehavior, lastRunStatus?: Types.GitHubBuildTriggerRunStatus | null, lastRunErrorCode?: string | null, lastRunErrorMessage?: string | null, lastRunBuild?: { __typename?: 'Build', id: string } | null }>, githubJobRunTriggers: Array<{ __typename?: 'GitHubJobRunTrigger', id: string, isActive: boolean, triggerType: Types.GitHubJobRunTriggerType, jobType?: Types.GitHubJobRunJobType | null, createdAt: any, lastRunAt?: any | null, sourcePattern: string, targetPattern?: string | null, lastRunStatus?: Types.GitHubJobRunTriggerRunStatus | null, lastRunErrorCode?: string | null, lastRunErrorMessage?: string | null }> } } };


export const GetGitHubRepoAndSettingsDocument = gql`
    query GetGitHubRepoAndSettings($fullName: String!) {
  app {
    byFullName(fullName: $fullName) {
      id
      githubRepository {
        id
        createdAt
        githubRepositoryIdentifier
        metadata {
          githubRepoDescription
          githubRepoName
          githubRepoOwnerName
          githubRepoUrl
          lastPushed
          lastUpdated
          private
        }
        githubAppInstallation {
          ...GitHubAppInstallationData
        }
      }
      githubRepositorySettings {
        baseDirectory
        id
      }
      githubBuildTriggers {
        ...GitHubBuildTriggerData
      }
      githubJobRunTriggers {
        ...GitHubJobRunTriggerData
      }
    }
  }
}
    ${GitHubAppInstallationDataFragmentDoc}
${GitHubBuildTriggerDataFragmentDoc}
${GitHubJobRunTriggerDataFragmentDoc}`;
export function useGetGitHubRepoAndSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables> & ({ variables: GetGitHubRepoAndSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>(GetGitHubRepoAndSettingsDocument, options);
      }
export function useGetGitHubRepoAndSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>(GetGitHubRepoAndSettingsDocument, options);
        }
export function useGetGitHubRepoAndSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>(GetGitHubRepoAndSettingsDocument, options);
        }
export type GetGitHubRepoAndSettingsQueryHookResult = ReturnType<typeof useGetGitHubRepoAndSettingsQuery>;
export type GetGitHubRepoAndSettingsLazyQueryHookResult = ReturnType<typeof useGetGitHubRepoAndSettingsLazyQuery>;
export type GetGitHubRepoAndSettingsSuspenseQueryHookResult = ReturnType<typeof useGetGitHubRepoAndSettingsSuspenseQuery>;
export type GetGitHubRepoAndSettingsQueryResult = Apollo.QueryResult<GetGitHubRepoAndSettingsQuery, GetGitHubRepoAndSettingsQueryVariables>;
export function refetchGetGitHubRepoAndSettingsQuery(variables: GetGitHubRepoAndSettingsQueryVariables) {
      return { query: GetGitHubRepoAndSettingsDocument, variables: variables }
    }