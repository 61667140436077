import sortBy from 'lodash/sortBy';

import { notEmpty } from '~/common/helpers';
import { AccountsDataFragment, UserInvitation } from '~/graphql/types.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';

export enum UserSidebarItem {
  USER_SETTINGS_ABOUT_YOU = 'USER_SETTINGS_ABOUT_YOU',
  USER_SETTINGS_EMAIL = 'USER_SETTINGS_EMAIL',
  USER_SETTINGS_CHANGE_PASSWORD = 'USER_SETTINGS_CHANGE_PASSWORD',
  USER_SETTINGS_TWO_FACTOR_AUTH = 'USER_SETTINGS_TWO_FACTOR_AUTH',
  USER_SETTINGS_LOG_OUT_OF_SESSIONS = 'USER_SETTINGS_LOG_OUT_OF_SESSIONS',
  USER_SETTINGS_CONVERT_TO_ORG = 'USER_SETTINGS_CONVERT_TO_ORG',
  USER_SETTINGS_RENAME_ACCOUNT = 'USER_SETTINGS_RENAME_ACCOUNT',
  USER_SETTINGS_DELETE_ACCOUNT = 'USER_SETTINGS_DELETE_ACCOUNT',
  USER_SETTINGS_CONNECTIONS = 'USER_SETTINGS_CONNECTIONS',
  USER_SETTINGS_EXPERIMENTS = 'USER_SETTINGS_EXPERIMENTS',
}

export enum SidebarItem {
  HOME = 'HOME',
  USER_PROFILE = 'USER_PROFILE',
  USER_SETTINGS = 'USER_SETTINGS',
  USER_SETTINGS_TWO_FACTOR_AUTH = 'USER_SETTINGS_TWO_FACTOR_AUTH',
  USER_SETTINGS_CONVERT_TO_ORG = 'USER_SETTINGS_CONVERT_TO_ORG',
  USER_SETTINGS_RENAME_ACCOUNT = 'USER_SETTINGS_RENAME_ACCOUNT',
  ACCOUNT_SNACKS = 'ACCOUNT_SNACKS',
  ACCOUNT_PROJECTS = 'ACCOUNT_PROJECTS',
  ACCOUNT_USAGE = 'ACCOUNT_USAGE',
  ACCOUNT_SETTINGS_OVERVIEW = 'ACCOUNT_SETTINGS_OVERVIEW',
  ACCOUNT_SETTINGS_ACCESS_TOKENS = 'ACCOUNT_SETTINGS_ACCESS_TOKENS',
  ACCOUNT_SETTINGS_BILLING = 'ACCOUNT_SETTINGS_BILLING',
  ACCOUNT_SETTINGS_AUDIT_LOGS = 'ACCOUNT_SETTINGS_AUDIT_LOGS',
  ACCOUNT_SETTINGS_SECRETS = 'ACCOUNT_SETTINGS_SECRETS',
  ACCOUNT_SETTINGS_CREDENTIALS = 'ACCOUNT_SETTINGS_CREDENTIALS',
  ACCOUNT_SETTINGS_APPLE_DEVICES = 'ACCOUNT_SETTINGS_APPLE_DEVICES',
  ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS = 'ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS',
  ACCOUNT_SETTINGS_GITHUB = 'ACCOUNT_SETTINGS_GITHUB',
  ACCOUNT_SETTINGS_MEMBERS = 'ACCOUNT_SETTINGS_MEMBERS',
  ACCOUNT_SETTINGS_RECEIPTS = 'ACCOUNT_SETTINGS_RECEIPTS',
  ACCOUNT_SETTINGS_ENVIRONMENT_VARIABLES = 'ACCOUNT_SETTINGS_ENVIRONMENT_VARIABLES',
  PROJECT_OVERVIEW = 'PROJECT_OVERVIEW',
  PROJECT_INSIGHTS = 'PROJECT_INSIGHTS',
  PROJECT_BUILDS = 'PROJECT_BUILDS',
  PROJECT_DEVELOPMENT_BUILDS = 'PROJECT_DEVELOPMENT_BUILDS',
  PROJECT_SERVERLESS_DEPLOYMENTS = 'PROJECT_SERVERLESS_DEPLOYMENTS',
  PROJECT_SERVERLESS_DEPLOYMENTS_OVERVIEW = 'PROJECT_SERVERLESS_DEPLOYMENTS_OVERVIEW',
  PROJECT_SERVERLESS_DEPLOYMENTS_METRICS = 'PROJECT_SERVERLESS_DEPLOYMENTS_METRICS',
  PROJECT_SERVERLESS_DEPLOYMENTS_LOGS = 'PROJECT_SERVERLESS_DEPLOYMENTS_LOGS',
  PROJECT_SERVERLESS_METRICS = 'PROJECT_SERVERLESS_METRICS',
  PROJECT_SERVERLESS_CRASHES = 'PROJECT_SERVERLESS_CRASHES',
  PROJECT_SERVERLESS_REQUESTS = 'PROJECT_SERVERLESS_REQUESTS',
  PROJECT_SERVERLESS_SETTINGS = 'PROJECT_SERVERLESS_SETTINGS',
  PROJECT_SUBMISSIONS = 'PROJECT_SUBMISSIONS',
  PROJECT_DEPLOYMENTS = 'PROJECT_DEPLOYMENTS',
  PROJECT_BRANCHES = 'PROJECT_BRANCHES',
  PROJECT_CHANNELS = 'PROJECT_CHANNELS',
  PROJECT_UPDATES = 'PROJECT_UPDATES',
  PROJECT_PUSH_NOTIFICATIONS = 'PROJECT_PUSH_NOTIFICATIONS',
  PROJECT_SETTINGS_OVERVIEW = 'PROJECT_SETTINGS_OVERVIEW',
  PROJECT_SETTINGS_CREDENTIALS = 'PROJECT_SETTINGS_CREDENTIALS',
  PROJECT_SETTINGS_GITHUB = 'PROJECT_SETTINGS_GITHUB',
  PROJECT_SETTINGS_SECRETS = 'PROJECT_SETTINGS_SECRETS',
  PROJECT_SETTINGS_ENVIRONMENT_VARIABLES = 'PROJECT_SETTINGS_ENVIRONMENT_VARIABLES',
  MOBILE_HOMEPAGE = 'MOBILE_HOMEPAGE', // Home is /, homepage is /home.
  MOBILE_DOCS = 'MOBILE_DOCS',
  MOBILE_GET_HELP = 'MOBILE_GET_HELP',
  MOBILE_TOOLS = 'MOBILE_TOOLS',
  MOBILE_EAS = 'MOBILE_EAS',
  MOBILE_PRICING = 'MOBILE_PRICING',
  MOBILE_CHANGELOG = 'MOBILE_CHANGELOG',
}

/**
 * # How to use it?
 *
 * This is a list of pairs: [prefix, sidebarItem]. Make sure that each sidebar
 * link has a corresponding entry in this list.
 *
 * Keep the list orderered by increasing specificity of the prefix. That way,
 * when searching over the list from the end, we are able to return roughly
 * sensible fallback for many cases (such as subpage of credentials or project
 * settings).
 */
const URL_TO_SIDEBAR_ITEM = [
  ['/settings', SidebarItem.USER_SETTINGS],
  ['/settings/rename-account', SidebarItem.USER_SETTINGS_RENAME_ACCOUNT],
  ['/settings/two-factor-auth', SidebarItem.USER_SETTINGS_TWO_FACTOR_AUTH],
  ['/settings/convert-account', SidebarItem.USER_SETTINGS_CONVERT_TO_ORG],
  ['/snacks', SidebarItem.ACCOUNT_SNACKS],
  ['/accounts/[account]/snacks', SidebarItem.ACCOUNT_SNACKS],
  ['/accounts/[account]/projects', SidebarItem.ACCOUNT_PROJECTS],
  ['/accounts/[account]/settings', SidebarItem.ACCOUNT_SETTINGS_OVERVIEW],
  ['/accounts/[account]/settings/usage', SidebarItem.ACCOUNT_USAGE],
  ['/accounts/[account]/settings/access-tokens', SidebarItem.ACCOUNT_SETTINGS_ACCESS_TOKENS],
  ['/accounts/[account]/settings/billing', SidebarItem.ACCOUNT_SETTINGS_BILLING],
  ['/accounts/[account]/settings/audit-logs', SidebarItem.ACCOUNT_SETTINGS_AUDIT_LOGS],
  ['/accounts/[account]/settings/secrets', SidebarItem.ACCOUNT_SETTINGS_SECRETS],
  [
    '/accounts/[account]/settings/environment-variables',
    SidebarItem.ACCOUNT_SETTINGS_ENVIRONMENT_VARIABLES,
  ],
  ['/accounts/[account]/settings/credentials', SidebarItem.ACCOUNT_SETTINGS_CREDENTIALS],
  ['/accounts/[account]/settings/apple-devices', SidebarItem.ACCOUNT_SETTINGS_APPLE_DEVICES],
  [
    '/accounts/[account]/settings/email-notifications',
    SidebarItem.ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS,
  ],
  ['/accounts/[account]/settings/members', SidebarItem.ACCOUNT_SETTINGS_MEMBERS],
  ['/accounts/[account]/settings/receipts', SidebarItem.ACCOUNT_SETTINGS_RECEIPTS],
  ['/accounts/[account]/projects/[projectName]', SidebarItem.PROJECT_OVERVIEW],
  ['/accounts/[account]/projects/[projectName]/insights', SidebarItem.PROJECT_INSIGHTS],
  ['/accounts/[account]/projects/[projectName]/builds', SidebarItem.PROJECT_BUILDS],
  [
    '/accounts/[account]/projects/[projectName]/development-builds',
    SidebarItem.PROJECT_DEVELOPMENT_BUILDS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/deployments',
    SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/deployments/[deploymentId]',
    SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_OVERVIEW,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/deployments/[deploymentId]/metrics',
    SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_METRICS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/deployments/[deploymentId]/logs',
    SidebarItem.PROJECT_SERVERLESS_DEPLOYMENTS_LOGS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/metrics',
    SidebarItem.PROJECT_SERVERLESS_METRICS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/crashes',
    SidebarItem.PROJECT_SERVERLESS_CRASHES,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/requests',
    SidebarItem.PROJECT_SERVERLESS_REQUESTS,
  ],
  [
    '/accounts/[account]/projects/[projectName]/serverless/settings',
    SidebarItem.PROJECT_SERVERLESS_SETTINGS,
  ],
  ['/accounts/[account]/projects/[projectName]/submissions', SidebarItem.PROJECT_SUBMISSIONS],
  ['/accounts/[account]/projects/[projectName]/deployments', SidebarItem.PROJECT_DEPLOYMENTS],
  ['/accounts/[account]/projects/[projectName]/branches', SidebarItem.PROJECT_BRANCHES],
  ['/accounts/[account]/projects/[projectName]/channels', SidebarItem.PROJECT_CHANNELS],
  ['/accounts/[account]/projects/[projectName]/updates', SidebarItem.PROJECT_UPDATES],
  ['/accounts/[account]/projects/[projectName]/settings', SidebarItem.PROJECT_SETTINGS_OVERVIEW],
  [
    '/accounts/[account]/projects/[projectName]/credentials',
    SidebarItem.PROJECT_SETTINGS_CREDENTIALS,
  ],
  ['/accounts/[account]/projects/[projectName]/github', SidebarItem.PROJECT_SETTINGS_GITHUB],
  ['/accounts/[account]/projects/[projectName]/secrets', SidebarItem.PROJECT_SETTINGS_SECRETS],
  [
    '/accounts/[account]/projects/[projectName]/environment-variables',
    SidebarItem.PROJECT_SETTINGS_ENVIRONMENT_VARIABLES,
  ],
  [
    '/accounts/[account]/projects/[projectName]/push-notifications',
    SidebarItem.PROJECT_PUSH_NOTIFICATIONS,
  ],
].reverse() as [string, SidebarItem][];

const MOBILE_LINK_MAPPING = [
  ['/home', SidebarItem.MOBILE_HOMEPAGE],
  ['https://docs.expo.dev', SidebarItem.MOBILE_DOCS],
  ['/support', SidebarItem.MOBILE_GET_HELP],
  ['/tools', SidebarItem.MOBILE_TOOLS],
  ['/eas', SidebarItem.MOBILE_EAS],
  ['/pricing', SidebarItem.MOBILE_PRICING],
  ['/changelog', SidebarItem.MOBILE_CHANGELOG],
] as [string, SidebarItem][];

export function getSelectedSidebarItem(
  pathname: string,
  fallback?: SidebarItem | null,
  isMobile: boolean = false
) {
  const items = isMobile ? [...MOBILE_LINK_MAPPING, ...URL_TO_SIDEBAR_ITEM] : URL_TO_SIDEBAR_ITEM;
  for (const [url, sidebarItem] of items) {
    if (pathname.startsWith(url)) {
      return sidebarItem;
    }
  }
  return fallback;
}

export function getPageName(isUserAccount: boolean, isTeamAccount: boolean) {
  if (isUserAccount) {
    return 'Account settings';
  }
  if (isTeamAccount) {
    return 'Team settings';
  }
  return 'Organization settings';
}

function isInvitationNotExpired(
  invitation: Omit<UserInvitation, 'isForOrganization'>,
  currentDateAsString?: string
) {
  const invitationExpiresDate = new Date(invitation.expires);
  return invitationExpiresDate > (currentDateAsString ? new Date(currentDateAsString) : new Date());
}

export function getUserInvitations(
  currentUser: LoggedInProps['currentUser'],
  currentDateAsString?: string
): UserInvitation[] {
  if (currentUser.__typename === 'User') {
    return currentUser.pendingUserInvitations.filter(
      (invitation) =>
        notEmpty(invitation) && isInvitationNotExpired(invitation, currentDateAsString)
    );
  }
  return [];
}

export function sortByAccountName(accounts: AccountsDataFragment[]) {
  return sortBy(
    accounts,
    (account: AccountsDataFragment) =>
      account.ownerUserActor?.fullName?.toLowerCase() ?? account.name?.toLowerCase()
  );
}
