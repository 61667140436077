import {
  createContext,
  type PropsWithChildren,
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from 'react';

type SidebarCollapsiblesContextType = {
  isProjectOpen: boolean;
  setIsProjectOpen: Dispatch<SetStateAction<boolean>>;
  isAccountOpen: boolean;
  setIsAccountOpen: Dispatch<SetStateAction<boolean>>;
  isServerlessOpen: boolean;
  setIsServerlessOpen: Dispatch<SetStateAction<boolean>>;
};

export const SidebarCollapsiblesContext = createContext<SidebarCollapsiblesContextType>({
  isProjectOpen: true,
  setIsProjectOpen: () => {},
  isAccountOpen: true,
  setIsAccountOpen: () => {},
  isServerlessOpen: true,
  setIsServerlessOpen: () => {},
});

export function SidebarCollapsiblesProvider({ children }: PropsWithChildren) {
  const [isProjectOpen, setIsProjectOpen] = useState(true);
  const [isAccountOpen, setIsAccountOpen] = useState(true);
  const [isServerlessOpen, setIsServerlessOpen] = useState(true);
  return (
    <SidebarCollapsiblesContext.Provider
      value={{
        isProjectOpen,
        setIsProjectOpen,
        isAccountOpen,
        setIsAccountOpen,
        isServerlessOpen,
        setIsServerlessOpen,
      }}>
      {children}
    </SidebarCollapsiblesContext.Provider>
  );
}

export function useSidebarCollapsiblesContext() {
  return useContext(SidebarCollapsiblesContext);
}
