import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { PlanEnterpriseIcon } from '@expo/styleguide-icons/custom/PlanEnterpriseIcon';
import { PlanFreeIcon } from '@expo/styleguide-icons/custom/PlanFreeIcon';
import { PlanOnDemandIcon } from '@expo/styleguide-icons/custom/PlanOnDemandIcon';
import { PlanProductionIcon } from '@expo/styleguide-icons/custom/PlanProductionIcon';
import { LifeBuoy01Icon } from '@expo/styleguide-icons/outline/LifeBuoy01Icon';
import { format } from 'date-fns/format';
import { Fragment, ReactElement, ReactNode } from 'react';

import { OffersDataFragment } from '~/graphql/fragments/OffersData.generated';
import { SubscriptionDataFragment } from '~/graphql/fragments/Subscription.fragment.generated';
import { AddonDetails, OfferPrerequisite, OfferType } from '~/graphql/types.generated';
import { PageProps } from '~/scenes/_app/helpers';
import { A } from '~/ui/components/text';

import { dateFnsFormats } from './constants';

const isProd = process.env.DEPLOYMENT_ENVIRONMENT === 'production';

const DeprecatedPlanNames = [
  'Community plan (2021)',
  'Priority plan (2021)',
  'Priority plan (yearly)',
  'Priority plan (Y Combinator)',
] as const;

type DeprecatedPlanName = (typeof DeprecatedPlanNames)[number];

export const PriorityPlanMonthlyNames = new Set([
  'Priority plan (2021)',
  'Priority plan (Y Combinator)',
]);

export type PlanName = 'Free' | 'On-demand' | 'Production' | 'Enterprise' | DeprecatedPlanName;

export enum SupportSlas {
  NONE,
  BILLING_ONLY,
  FIVE_BUSINESS_DAYS,
  TWO_TO_THREE_BUSINESS_DAYS,
  TWO_BUSINESS_DAYS,
}

/*
These plans are ranked in order of price
They are frozen to prevent the order from being mutated
*/
export const planTiers: Record<PlanName, number> = Object.freeze({
  'Community plan (2021)': 0,
  Free: 0,
  'On-demand': 1,
  'Priority plan (2021)': 2,
  'Priority plan (yearly)': 2,
  'Priority plan (Y Combinator)': 2,
  Production: 3,
  Enterprise: 4,
});

export type PlanInfo = {
  title: PlanName;
  description?: string;
  subscriptionPeriod: 'month' | 'year';
  offer?: OffersDataFragment;
  learnMoreLink?: string;
  icon?: (props?: { className?: string }) => ReactElement;
  supportSla: SupportSlas;
  concurrenciesCount: number;
};

export type AddonInfo = {
  stripeId: string;
  title: string;
  description?: string;
  price?: number;
  subscriptionPeriod: 'month' | 'year';
  learnMoreLink?: string;
  icon?: (props?: { className?: string }) => ReactElement;
};

export type AddonInfoWithDetails = AddonInfo &
  AddonDetails & {
    meetsPrerequisite: boolean;
  };

const standardOffersPlanIdsProd = {
  DEFAULT: 'plan_FRR7eAq4nX8LJQ',
  YEARLY_SUB: 'plan_GnN3ei9199ag61',
  YC_DEALS: 'plan_FRohMR0tweFCWv',
};

const standardOffersPlanIdsStaging = {
  DEFAULT: 'plan_EtoTEVOr3dXyUN',
  YEARLY_SUB: 'price_1IfsVREnlKOkR6exotWLTizq',
  YC_DEALS: 'price_1Ii1V9EnlKOkR6exyRBzR8z5',
};

export const standardOffersPlanIds = isProd
  ? standardOffersPlanIdsProd
  : standardOffersPlanIdsStaging;

const stripeCheckoutStandardOffersProd = {
  FREE: 'price_free',
  ON_DEMAND: 'price_1LaLS1EnlKOkR6exYKbo4aQE',
  PRODUCTION: 'price_1Ji2seEnlKOkR6ex58qHJo0h',
  ENTERPRISE: 'price_1Ji2skEnlKOkR6exiN7cgiKc',
};

const stripeCheckoutStandardOffersStaging = {
  FREE: 'price_free',
  ON_DEMAND: 'price_1LW0YSEnlKOkR6excNgwbIP9',
  PRODUCTION: 'price_1JRiHtEnlKOkR6exWsdblUOD',
  ENTERPRISE: 'price_1JRiIFEnlKOkR6exIAF9uHhb',
};

export const stripeCheckoutOffers = isProd
  ? stripeCheckoutStandardOffersProd
  : stripeCheckoutStandardOffersStaging;

// These are annual plans that are set manually upon request
// They are not offered on our site
const stripeCheckoutAnnualOffersProd = {
  FREE: 'price_free',
  ON_DEMAND: 'price_1LaLS1EnlKOkR6ex4gRqYc2G',
  PRODUCTION: 'price_1KUJbCEnlKOkR6exjzHb3NAT',
  ENTERPRISE: 'price_1KbBRbEnlKOkR6exipiqZ2l7',
};

// These are annual plans that are set manually upon request
// They are not offered on our site
const stripeCheckoutAnnualOffersStaging = {
  FREE: 'price_free',
  ON_DEMAND: 'price_1LW0Z9EnlKOkR6exrzV3cCOA',
  PRODUCTION: 'price_1Kb9ibEnlKOkR6exkBLoj5WG',
  ENTERPRISE: 'price_1L6dw7EnlKOkR6exm1oWUyOG',
};

export const stripeCheckoutAnnualOffers = isProd
  ? stripeCheckoutAnnualOffersProd
  : stripeCheckoutAnnualOffersStaging;

const stripeCheckoutAddonsProd = {
  ENTERPRISE_SUPPORT: 'price_1Ji2snEnlKOkR6exfeQgboPP',
  ENTERPRISE_SUPPORT_ANNUAL: 'price_1Klk1AEnlKOkR6exCM9X8Ykz',
  ENTERPRISE_SUPPORT_ANNUAL_ONE_TIME: 'price_1KdQ9dEnlKOkR6exjkr2CTlL',
  ADDITIONAL_CONCURRENCY: 'price_1PabGiEnlKOkR6exxNFNoKeI',
  ADDITIONAL_CONCURRENCY_ANNUAL: 'price_1PabH6EnlKOkR6exCvzmcR50',
  ADDITIONAL_CONCURRENCY_LEGACY: 'price_1LAhQlEnlKOkR6exAIKmvrvn',
  ADDITIONAL_CONCURRENCY_ANNUAL_LEGACY: 'price_1LAhQlEnlKOkR6exVyLrxNSC',
};

const stripeCheckoutAddonsStaging = {
  ENTERPRISE_SUPPORT: 'price_1JRiIsEnlKOkR6exHG4gKYkO',
  ENTERPRISE_SUPPORT_ANNUAL: 'price_1Klk2iEnlKOkR6ex22y4dE4H',
  ENTERPRISE_SUPPORT_ANNUAL_ONE_TIME: 'price_1Klk2YEnlKOkR6exNb2WiBaL',
  ADDITIONAL_CONCURRENCY: 'price_1KyHELEnlKOkR6exHZM2Kj7K',
  ADDITIONAL_CONCURRENCY_ANNUAL: 'price_1LAhQVEnlKOkR6exvEcLOYQg',
  ADDITIONAL_CONCURRENCY_LEGACY: 'price_1PabQWEnlKOkR6exJcqomA5N',
  ADDITIONAL_CONCURRENCY_ANNUAL_LEGACY: 'price_1PabR3EnlKOkR6exnA4NaHIg',
};

export const stripeCheckoutAddons = isProd ? stripeCheckoutAddonsProd : stripeCheckoutAddonsStaging;

export enum PlanType {
  FREE = 'FREE',
  PRODUCTION = 'PRODUCTION',
  ENTERPRISE = 'ENTERPRISE',
  PRIORITY = 'PRIORITY',
}

const community: PlanInfo = {
  title: 'Community plan (2021)',
  description:
    'Get started building your first Expo app or continue developing with your current workflow.',
  subscriptionPeriod: 'month',
  supportSla: SupportSlas.NONE,
  concurrenciesCount: 1,
};

const priorityMonthly: PlanInfo = {
  title: 'Priority plan (2021)',
  description:
    'Manage your app as a team and rebuild your app as often as you want, when you want.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/pricing',
  icon: (props) => <PlanProductionIcon {...props} />,
  supportSla: SupportSlas.TWO_TO_THREE_BUSINESS_DAYS,
  concurrenciesCount: 1,
};

const priorityYearly: PlanInfo = {
  title: 'Priority plan (yearly)',
  description: priorityMonthly.description,
  subscriptionPeriod: 'year',
  supportSla: SupportSlas.TWO_TO_THREE_BUSINESS_DAYS,
  concurrenciesCount: 1,
};

const priorityYcombinator: PlanInfo = {
  ...priorityMonthly,
  title: 'Priority plan (Y Combinator)',
};

export const stripeCheckoutFree: PlanInfo = {
  title: 'Free',
  description: 'For startups, proofs-of-concept, hobbyists, and student projects.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/pricing',
  icon: (props) => <PlanFreeIcon {...props} />,
  supportSla: SupportSlas.NONE,
  concurrenciesCount: 1,
};

export const stripeCheckoutOnDemand: PlanInfo = {
  title: 'On-demand',
  description:
    'For growing projects with variable usage. There is no monthly subscription fee, but usage is charged at usage-based rates.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/pricing',
  icon: (props) => <PlanOnDemandIcon {...props} />,
  supportSla: SupportSlas.BILLING_ONLY,
  concurrenciesCount: 1,
};

export const stripeCheckoutProduction: PlanInfo = {
  title: 'Production',
  description: 'For any project that requires production-grade services.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/pricing',
  icon: (props) => <PlanProductionIcon {...props} />,
  supportSla: SupportSlas.FIVE_BUSINESS_DAYS,
  concurrenciesCount: 2,
};

export const stripeCheckoutEnterprise: PlanInfo = {
  title: 'Enterprise',
  description:
    'For large projects and organizations that need additional resources and direct support.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/pricing',
  icon: (props) => <PlanEnterpriseIcon {...props} />,
  supportSla: SupportSlas.TWO_BUSINESS_DAYS,
  concurrenciesCount: 5,
};

export const stripeCheckoutEnterpriseSupportAddon: AddonInfo = {
  stripeId: stripeCheckoutAddons.ENTERPRISE_SUPPORT,
  title: 'Enterprise Support',
  description:
    'Receive prioritized support from the Expo team regarding issues with EAS to keep your production apps healthy.',
  subscriptionPeriod: 'month',
  learnMoreLink: '/eas/enterprise-support',
  icon: (props) => <LifeBuoy01Icon {...props} />,
};

export const stripeCheckoutEnterpriseSupportAddonAnnual: AddonInfo = {
  stripeId: stripeCheckoutAddons.ENTERPRISE_SUPPORT_ANNUAL,
  title: 'Enterprise Support',
  description:
    'Receive prioritized support from the Expo team regarding issues with EAS to keep your production apps healthy.',
  subscriptionPeriod: 'year',
  learnMoreLink: '/eas/enterprise-support',
  icon: (props) => <LifeBuoy01Icon {...props} />,
};

export const stripeCheckoutEnterpriseSupportAddonAnnualOneTime: AddonInfo = {
  ...stripeCheckoutEnterpriseSupportAddonAnnual,
  stripeId: stripeCheckoutAddons.ENTERPRISE_SUPPORT_ANNUAL_ONE_TIME,
};

export const stripeCheckoutAdditionalConcurrencyAddon: AddonInfo = {
  stripeId: stripeCheckoutAddons.ADDITIONAL_CONCURRENCY,
  title: 'Additional Concurrency – EAS Build',
  description: 'Allows running more Android and iOS builds at once.',
  subscriptionPeriod: 'month',
  icon: (props) => <BuildIcon {...props} />,
};

export const stripeCheckoutAdditionalConcurrencyAnnualAddon: AddonInfo = {
  stripeId: stripeCheckoutAddons.ADDITIONAL_CONCURRENCY_ANNUAL,
  title: 'Additional Concurrency – EAS Build',
  description: 'Allows running more Android and iOS builds at once.',
  subscriptionPeriod: 'year',
  icon: (props) => <BuildIcon {...props} />,
};

export const stripeCheckoutAdditionalConcurrencyAddonLegacy: AddonInfo = {
  stripeId: stripeCheckoutAddons.ADDITIONAL_CONCURRENCY_LEGACY,
  title: 'EAS Build – Additional Concurrency (Legacy price)',
  description: `We'll take care of migrating your account to the new price. Thank you for being a longtime EAS subscriber!`,
  subscriptionPeriod: 'month',
  icon: (props) => <BuildIcon {...props} />,
};

export const stripeCheckoutAdditionalConcurrencyAnnualAddonLegacy: AddonInfo = {
  stripeId: stripeCheckoutAddons.ADDITIONAL_CONCURRENCY_ANNUAL_LEGACY,
  title: 'EAS Build – Additional Concurrency (Legacy price)',
  description: `We'll take care of migrating your account to the new price. Thank you for being a longtime EAS subscriber!`,
  subscriptionPeriod: 'year',
  icon: (props) => <BuildIcon {...props} />,
};

const supportedAddons = [
  stripeCheckoutEnterpriseSupportAddon,
  stripeCheckoutEnterpriseSupportAddonAnnual,
  stripeCheckoutEnterpriseSupportAddonAnnualOneTime,
  stripeCheckoutAdditionalConcurrencyAddon,
  stripeCheckoutAdditionalConcurrencyAnnualAddon,
  stripeCheckoutAdditionalConcurrencyAddonLegacy,
  stripeCheckoutAdditionalConcurrencyAnnualAddonLegacy,
];

export const plans = { community, priorityMonthly, priorityYearly, priorityYcombinator };

export const planHighlights: { [key in PlanInfo['title']]?: (string | ReactNode)[] } = {
  [stripeCheckoutFree.title]: [
    'Access to EAS Build and Submit',
    '1 build at a time',
    '45-minute build timeout',
  ],
  [stripeCheckoutProduction.title]: [
    'Everything from the Free plan',
    'Priority builds',
    '2 concurrent builds',
    '2-hour build timeout',
    'Early access to preview new EAS products & features',
  ],
  [stripeCheckoutEnterprise.title]: [
    'Everything from the Production plan',
    '5 concurrent builds',
    '2-hour build timeout',
    'EAS Build and EAS Submit SLAs',
    'Prioritized support',
    <Fragment key="enterprise-add-on">
      <A isStyled href="/eas/enterprise-support">
        Enterprise Support Add-on
      </A>{' '}
      available for purchase.{' '}
    </Fragment>,
  ],
};

// the API server no longer provides priority plans as a part of 'offers' array
// however, our rendering logic requires a priority plan in some scenarios
export const getAvailablePlansWithPriorityPlanIfOnPriority = (
  availablePlans: PlanInfo[],
  currentSubscription?: SubscriptionDataFragment | null
): PlanInfo[] => {
  const { price, planId } = currentSubscription ?? {};
  if (!(price && planId && availablePlans.length)) {
    return availablePlans;
  }

  const planFromPlanId = getPlanFromPlanId(planId);
  if (!PriorityPlanMonthlyNames.has(planFromPlanId.title)) {
    return availablePlans;
  }

  const priorityOffer: OffersDataFragment = {
    id: planId,
    price,
    stripeId: planId,
    type: OfferType.Subscription,
    __typename: 'Offer',
  };

  return [
    ...availablePlans,
    {
      ...planFromPlanId,
      offer: priorityOffer,
    },
  ].sort((a, b) => ((a.offer?.price ?? 0) > (b.offer?.price ?? 0) ? 1 : -1));
};

export const appendFreePlanIfNotAlreadyOnFree = (
  availablePlans: PlanInfo[],
  currentSubscription?: SubscriptionDataFragment | null
) => {
  const { planId } = currentSubscription ?? {};
  if (planId === stripeCheckoutOffers.FREE) {
    return availablePlans;
  }

  const planFromPlanId = getPlanFromPlanId(stripeCheckoutOffers.FREE);

  const freeOffer: OffersDataFragment = {
    id: stripeCheckoutOffers.FREE,
    price: 0,
    stripeId: stripeCheckoutOffers.FREE,
    type: OfferType.Subscription,
    __typename: 'Offer',
  };

  return [
    ...availablePlans,
    {
      ...planFromPlanId,
      offer: freeOffer,
    },
  ];
};

export const getPlanFromOffer = (offer: OffersDataFragment): PlanInfo => {
  // note(Kirby): id should be synonymous with stripeId
  return { ...getPlanFromPlanId(offer.stripeId ?? offer.id), offer };
};

export const getPlanFromPlanId = (planId: string): PlanInfo => {
  switch (planId) {
    case standardOffersPlanIds.DEFAULT:
      return priorityMonthly;
    case standardOffersPlanIds.YC_DEALS:
      return priorityYcombinator;
    case standardOffersPlanIds.YEARLY_SUB:
      return priorityYearly;
    case stripeCheckoutOffers.FREE:
      return stripeCheckoutFree;
    case stripeCheckoutOffers.ON_DEMAND:
      return stripeCheckoutOnDemand;
    case stripeCheckoutOffers.PRODUCTION:
      return stripeCheckoutProduction;
    case stripeCheckoutOffers.ENTERPRISE:
      return stripeCheckoutEnterprise;
    case stripeCheckoutAnnualOffers.FREE:
      return {
        ...stripeCheckoutFree,
        subscriptionPeriod: 'year',
      };
    case stripeCheckoutAnnualOffers.ON_DEMAND:
      return {
        ...stripeCheckoutOnDemand,
        subscriptionPeriod: 'year',
      };
    case stripeCheckoutAnnualOffers.PRODUCTION:
      return {
        ...stripeCheckoutProduction,
        subscriptionPeriod: 'year',
      };
    case stripeCheckoutAnnualOffers.ENTERPRISE:
      return {
        ...stripeCheckoutEnterprise,
        subscriptionPeriod: 'year',
      };
    default:
      return community;
  }
};

export const getPlanFromName = (name?: string | null): PlanInfo => {
  const nameToPlanMap = {
    'y combinator': priorityYcombinator,
    yearly: priorityYearly,
    'priority plan': priorityMonthly,
    'free tier': community,
    free: stripeCheckoutFree,
    'on-demand': stripeCheckoutOnDemand,
    production: stripeCheckoutProduction,
    enterprise: stripeCheckoutEnterprise,
  };

  const lowercasedName = name?.toLowerCase() ?? 'community';

  const matched = Object.entries(nameToPlanMap).find(([name]) => lowercasedName.match(name));
  if (matched) {
    return matched[1];
  }

  return community;
};

export const getAddonFromOffer = (offer: OffersDataFragment) => {
  return supportedAddons.find((addon) => addon.stripeId === offer.stripeId) ?? null;
};

export const meetsPrerequisite = (
  selectedPlanIds: Set<string>,
  prerequisite?: OfferPrerequisite | null
): boolean => {
  if (!prerequisite?.stripeIds || prerequisite.stripeIds.length === 0) {
    return true;
  }

  if (prerequisite.type === 'AND') {
    return prerequisite.stripeIds.every((stripeId) => selectedPlanIds.has(stripeId));
  }

  return prerequisite.stripeIds.some((stripeId) => selectedPlanIds.has(stripeId));
};

export function getPlanChangeDate(currentSubscription: SubscriptionDataFragment) {
  if (currentSubscription && currentSubscription.name === stripeCheckoutEnterprise.title) {
    return `Starts ${format(new Date(currentSubscription.nextInvoice), dateFnsFormats.timestamp)}`;
  } else {
    return '';
  }
}

export function isSelectedPlanADowngrade(
  currentSubscription?: SubscriptionDataFragment | null,
  selectedPlan?: PlanInfo | null
) {
  if (!currentSubscription || !selectedPlan) {
    return false;
  }

  return (
    (currentSubscription.name === stripeCheckoutEnterprise.title &&
      selectedPlan.title === stripeCheckoutProduction.title) ||
    selectedPlan.title === stripeCheckoutOnDemand.title
  );
}

/**
 * Since users can belong to multiple accounts, this method returns the highest
 * tier plan of any account that a user has any permissions on.
 */
export function getHighestPlanFromUser(user: PageProps['currentUser']) {
  if (!user) {
    return null;
  }

  return user.accounts
    .map(
      (account) =>
        getPlanFromPlanId(account.subscription?.planId ?? stripeCheckoutOffers.FREE).title
    )
    .sort((a, b) => planTiers[b] - planTiers[a])[0];
}

export const stripeCheckoutSubscriptionPlanIds = new Set(Object.values(stripeCheckoutOffers));
export const stripeCheckoutAnnualSubscriptionPlanIds = new Set(
  Object.values(stripeCheckoutAnnualOffers)
);
const legacySubscriptionPlanIds = new Set(Object.values(standardOffersPlanIds));

export function isStripePlanIdentifierASubscriptionPlan(stripePlanIdentifier: string): boolean {
  return (
    legacySubscriptionPlanIds.has(stripePlanIdentifier) ||
    stripeCheckoutSubscriptionPlanIds.has(stripePlanIdentifier) ||
    stripeCheckoutAnnualSubscriptionPlanIds.has(stripePlanIdentifier)
  );
}
