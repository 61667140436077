import { mergeClasses } from '@expo/styleguide';

import { CALLOUT } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

import { FooterLinks } from './FooterLinks';
import { MobileFeedback } from './MobileFeedback';
import { ServiceStatusIndicator } from './ServiceStatusIndicator';
import { SocialLinks } from './SocialLinks';
import { getCompanyLinks, getLegalLinks, getProductLinks, getResourceLinks } from './links';
import { ThemeSelector } from '../ThemeSelector';

const productLinks = getProductLinks();
const resourceLinks = getResourceLinks();
const companyLinks = getCompanyLinks();
const legalLinks = getLegalLinks();

export default function Footer() {
  return (
    <footer className="border-t border-t-default bg-default">
      <Content className={mergeClasses('pb-20 pt-16', 'max-md-gutters:pb-10 max-md-gutters:pt-8')}>
        <nav
          className={mergeClasses(
            'grid grid-cols-4 gap-8',
            'max-md-gutters:grid-cols-1 max-md-gutters:gap-0'
          )}
          aria-label="Expo Directory"
          role="navigation">
          <FooterLinks label="Product" links={productLinks} />
          <FooterLinks label="Resources" links={resourceLinks} />
          <FooterLinks label="Company" links={companyLinks} />
          <FooterLinks label="Legal" links={legalLinks} />
          <MobileFeedback />
        </nav>
        <section
          className={mergeClasses(
            'mt-20 flex items-center justify-between gap-6',
            'max-md-gutters:mt-8 max-md-gutters:flex-wrap-reverse'
          )}>
          <div className="flex flex-col gap-4">
            <SocialLinks />
            <CALLOUT theme="secondary">
              Copyright &copy; {new Date().getUTCFullYear()} 650 Industries, Inc. All rights
              reserved.
            </CALLOUT>
          </div>
          <div
            className={mergeClasses(
              'flex flex-1 items-center justify-end gap-4',
              'max-md-gutters:justify-between',
              'max-sm-gutters:flex-col'
            )}>
            <ServiceStatusIndicator />
            <ThemeSelector />
          </div>
        </section>
      </Content>
    </footer>
  );
}
