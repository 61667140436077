export const zIndex = {
  popovers: 100,
  tooltips: 200,
  controls: 300,
  overlays: 400,
  overlayControls: 500,
  globalPopovers: 600,
  selectPopovers: 700,
  toasts: 800,
};
