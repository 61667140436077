import { mergeClasses } from '@expo/styleguide';
import { FormHTMLAttributes, FormEvent, forwardRef, Ref } from 'react';
import * as yup from 'yup';

import { capitalizeFirstLetter } from '~/common/strings';
import type { ValidationErrors } from '~/ui/components/form/ValidationErrors';

type FormProps = FormHTMLAttributes<HTMLFormElement> & {
  onSubmit?: (event: FormEvent) => void;
  disabled?: boolean;
  variant?: 'flat' | 'shadow' | 'popout';
};

type CustomFormEvent = FormEvent & {
  target: {
    elements: HTMLFormElement[];
  };
};

// note(Jon): any is purposeful here. allows use to handle string or boolean values without writing type guards to type narrow a string | boolean union
type FormData = Record<string, any>;

export function transformYupErrors(error: yup.ValidationError) {
  return error.inner.reduce((acc, field) => {
    if (typeof field.path !== 'string') {
      return acc;
    }
    return {
      ...acc,
      [field.path]: `${capitalizeFirstLetter(field.message)}.`,
    };
  }, {});
}

export function getFormFieldData(event: FormEvent) {
  return parseFormElements((event as CustomFormEvent).target.elements);
}

function setFormValue(acc: FormData, name: string, value: string) {
  const match = name.match(/(.+)\[(\d+)]$/);
  if (match) {
    const [key, index] = [match[1], match[2]];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key][parseInt(index, 10)] = value;
  } else {
    acc[name] = value;
  }
}

export function parseFormElements(elements: HTMLFormElement[]) {
  return Object.values(elements).reduce((acc, field) => {
    if (field.id) {
      if (field.type === 'radio') {
        if (!acc[field.name]) {
          if (field.checked) {
            setFormValue(acc, field.name, field.id);
          } else {
            setFormValue(acc, field.name, '');
          }
        }
      } else {
        setFormValue(acc, field.id, field.hasOwnProperty('checked') ? field.checked : field.value);
      }
    } else if (field.name) {
      setFormValue(acc, field.name, field.hasOwnProperty('checked') ? field.checked : field.value);
    }

    return acc;
  }, {} as FormData);
}

export async function getValidatedFormDataAsync(
  formData: FormData,
  schema: yup.AnySchema
): Promise<[FormData] | [FormData, ValidationErrors]> {
  try {
    await schema.validate(formData, { abortEarly: false });
    return [formData];
  } catch (error) {
    return [formData, transformYupErrors(error as yup.ValidationError)];
  }
}

export async function getValidatedFormFieldDataAsync(
  event: FormEvent,
  schema: yup.AnySchema
): Promise<[FormData] | [FormData, ValidationErrors]> {
  const formData = getFormFieldData(event);
  return await getValidatedFormDataAsync(formData, schema);
}

export const Form = forwardRef(function Form(
  { onSubmit, disabled, variant, className, ...rest }: FormProps,
  ref?: Ref<HTMLFormElement>
) {
  return (
    <form
      action="/form-error"
      method="post"
      className={mergeClasses(
        'flex flex-col rounded-lg',
        variant === 'flat' && 'shadow-none',
        variant === 'shadow' && 'shadow-xs',
        variant === 'popout' && 'relative z-[1] -my-0.5 -ml-1 w-[calc(100%+8px)] shadow-sm',
        className
      )}
      ref={ref}
      {...rest}
      onSubmit={(event) => {
        event.preventDefault();

        if (disabled) {
          return;
        }

        if (onSubmit) {
          onSubmit(event);
        }
      }}
    />
  );
});
