import { mergeClasses } from '@expo/styleguide';

type Props = {
  className?: string;
};

export function StaffBadge({ className }: Props) {
  return (
    <div
      className={mergeClasses(
        'absolute min-w-[40px] rounded-full bg-palette-blue10 text-center text-[10px] font-semibold text-palette-white',
        'dark:bg-palette-blue8',
        className
      )}>
      STAFF
    </div>
  );
}
