import { mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { ChevronDownIcon } from '@expo/styleguide-icons/outline/ChevronDownIcon';
import * as HoverCard from '@radix-ui/react-hover-card';

import * as StaticData from '~/common/static-data';
import { Button } from '~/ui/components/Button';
import { A, CALLOUT, FOOTNOTE } from '~/ui/components/text';

type Props = {
  isToolsPage?: boolean;
};

export function ToolsButton({ isToolsPage }: Props) {
  return (
    <HoverCard.Root openDelay={0} closeDelay={500}>
      <HoverCard.Trigger asChild>
        <Button
          prefetch={false}
          theme="quaternary"
          href={!isToolsPage ? '/tools' : undefined}
          rightSlot={<ChevronDownIcon className="icon-sm text-icon-secondary" />}>
          Tools
        </Button>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          sideOffset={-2}
          sticky="always"
          className={mergeClasses(
            'flex flex-col gap-0.5 rounded-lg border border-default bg-default p-1 shadow-md',
            'will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
          )}>
          <div className="absolute -top-3 left-0 h-3 w-[560px]" />
          <div className="grid w-[560px] grid-cols-2 gap-x-4 gap-y-2 p-3">
            {StaticData.products.map((product) => (
              <A href={product.url} openInNewTab={product.openInNewTab} key={product.url}>
                <div
                  className={mergeClasses(
                    'relative z-40 flex h-full cursor-pointer select-none items-center justify-between rounded-sm px-3 py-2 outline-0',
                    'hocus:bg-hover'
                  )}>
                  <div className="flex flex-1 flex-col gap-0.5 self-start">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <CALLOUT>{product.name}</CALLOUT>
                      </div>
                      {product.openInNewTab && (
                        <ArrowUpRightIcon className="icon-sm text-icon-secondary" />
                      )}
                    </div>
                    {product.description && (
                      <FOOTNOTE theme="tertiary" className="!leading-[18px]">
                        {product.description}
                      </FOOTNOTE>
                    )}
                  </div>
                </div>
              </A>
            ))}
          </div>
          <HoverCard.Arrow asChild>
            <div className="relative -top-1 size-2.5 rotate-45 border-b border-r border-default bg-default" />
          </HoverCard.Arrow>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
