import { mergeClasses } from '@expo/styleguide';
import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import { Label } from './Label';

export type FormGroupProps = PropsWithChildren<{
  htmlFor?: string;
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  style?: CSSProperties;
  icon?: ReactNode;
  titleRightAccessory?: ReactNode;
  marginOnLastChild?: boolean;
}>;

export function FormGroup({
  title,
  description,
  className,
  htmlFor,
  style,
  children,
  icon,
  titleRightAccessory,
  marginOnLastChild = false,
  ...rest
}: FormGroupProps) {
  const labelComponent =
    title || description || icon ? (
      <Label title={title} description={description} htmlFor={htmlFor} icon={icon} />
    ) : null;

  const childrenMargin = marginOnLastChild ? '[&>*]:mr-4' : '[&>*:not(:last-child)]:mr-4';

  return (
    <div className={mergeClasses('flex-1', className)} style={style} {...rest}>
      <div className="flex items-center justify-between">
        {labelComponent}
        {titleRightAccessory}
      </div>
      <div className={mergeClasses('flex [&>*]:mb-0', childrenMargin)}>{children}</div>
    </div>
  );
}
