import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class UserAndAccountBackgroundDeletionFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.USER_AND_ACCOUNT_BACKGROUND_DELETION;
  }
}

export function useUserAndAccountBackgroundDeletionFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.USER_AND_ACCOUNT_BACKGROUND_DELETION] ?? defaultGate;
}
