export const defaultFullyRolledOutProductionAccounts = new Set([
  '23be52f4-2a46-4acb-8e46-49e715983399', // bycedric
  'a99da9a2-208f-459b-860b-65c01b222542', // ccheever
  '384db21a-467a-44c8-91bb-061cb5d83f18', // fiberjw
  'e52546a2-d8c5-4c47-b153-893f4452a05a', // ide
  'fac431f8-58cb-4a80-8599-6f8c683f27bd', // jess
  '7128d26c-18b9-4026-b106-a0ceaa6312fb', // simek
  'ca05650c-b861-4e89-8e11-5bf5e19d101c', // expo
  '48310d73-add6-4b93-aeb9-45f929faf376', // exponent
]);
