import { mergeClasses } from '@expo/styleguide';
import { ChevronRightIcon } from '@expo/styleguide-icons/outline/ChevronRightIcon';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import { ComponentType, HTMLAttributes, ReactNode, useEffect, useState } from 'react';

import { CALLOUT } from '~/ui/components/text';

type CollapsibleProps = {
  title: string;
  Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  children: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export function SidebarCollapsible({
  Icon,
  title,
  children,
  defaultOpen,
  onOpenChange,
}: CollapsibleProps) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <CollapsiblePrimitive.Root
      open={defaultOpen}
      onOpenChange={(open) => {
        onOpenChange?.(open);
        setOpen(open);
      }}>
      <CollapsiblePrimitive.Trigger className="flex h-10 w-full items-center justify-between rounded-md px-3 hover:bg-subtle">
        <div className="flex items-center gap-2">
          {Icon && <Icon />}
          <CALLOUT>{title}</CALLOUT>
        </div>
        <ChevronRightIcon
          className={mergeClasses(
            'icon-sm transform text-icon-secondary transition-transform duration-200',
            open && 'rotate-90'
          )}
        />
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content className="mt-1 flex flex-col gap-1 pl-3">
        {children}
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
}
