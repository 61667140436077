import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@expo/styleguide';
import { MDXProvider } from '@mdx-js/react';
import { init as sentryInit, browserTracingIntegration, ErrorBoundary } from '@sentry/react';
import { MotionConfig } from 'framer-motion';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { NextComponentType, NextPageContext } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import { Inter, Fira_Code } from 'next/font/google';
import Head from 'next/head';
import { useRef, useEffect } from 'react';

import {
  identify,
  identifyAnonymous,
  track,
  events,
  getGTagScriptTags,
  getRudderStackScriptTags,
  googleAnalyticsId,
} from '~/common/analytics';
import { APIV2Client } from '~/common/api-v2-client';
import { printDevMessages } from '~/common/dev-mode';
import { FeatureGateContext } from '~/common/gating/FeatureGateContext';
import { getCookieFeatureGateQueryParams } from '~/common/gating/getCookieFeatureGateQueryParams';
import { hasURLFeatureGateOverrides } from '~/common/gating/hasURLFeatureGateOverrides';
import { getFeatureGates } from '~/common/gating/setup';
import { getParamString } from '~/common/getParamString';
import { createClient } from '~/common/graphql-client';
import { preprocessSentryError } from '~/common/sentry-utilities';
import { createSudoUpgradeLink } from '~/common/sudo-upgrade-link';
import { useNProgress } from '~/common/use-nprogress';
import { useCurrentUserQuery } from '~/graphql/queries/CurrentUserQuery.query.generated';
import { AuthFormContextProvider } from '~/providers/AuthFormContext';
import { ExperimentationContext, ExperimentationManager } from '~/providers/ExperimentationContext';
import { LogsDisplayOptionsProvider } from '~/providers/LogsDisplayOptionsContext';
import { PagePropsContext } from '~/providers/PagePropsContext';
import { SearchDialogProvider } from '~/providers/SearchDialogContext';
import { SidebarCollapsiblesProvider } from '~/providers/SidebarCollapsiblesContext';
import { ToastProvider } from '~/providers/Toast';
import { APIV2Context } from '~/providers/useAPIV2';
import { FeatureGateURLQueryParamsContextProvider } from '~/providers/useFeatureGateURLQueryParams';
import { EnvBanner } from '~/scenes/_app/EnvBanner';
import { LinkResolverPage } from '~/scenes/_app/LinkResolverPage';
import { type PageProps, getPersistentFeatureGateQueryParams } from '~/scenes/_app/helpers';
import Document from '~/ui/components/Document';
import { ErrorPage } from '~/ui/components/ErrorPage';
import Footer from '~/ui/components/Footer';
import { MarkdownComponents } from '~/ui/components/Markdown';
import { Navigation } from '~/ui/components/Navigation';
import { Search } from '~/ui/components/Navigation/Search';

import '../styles/reset.css';
import '../styles/globals.css';
import '../styles/prism.css';
import '../styles/diff.css';
import '../styles/date-picker-theme.css';

import '@expo/styleguide/dist/expo-theme.css';
import '@expo/styleguide-search-ui/dist/expo-search-ui.css';

import '~/scenes/Dashboard/components/DashboardLogs/Ansi/ansi.css';
import '~/scenes/LaunchPartyScene/theme.css';
import '~/scenes/GraphiQL/styles.css';

if (typeof window !== 'undefined') {
  sentryInit({
    dsn: 'https://d5e46a627eab49e9aeaec935f07f2b45@sentry.io/1813573',
    enabled: ['production', 'staging'].includes(process.env.DEPLOYMENT_ENVIRONMENT ?? ''),
    environment: process.env.DEPLOYMENT_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,
    beforeSend: preprocessSentryError,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.005,
    normalizeDepth: 10,
  });
  // note(simek): PROD setup, uncomment in the future
  // if (process.env.DEPLOYMENT_ENVIRONMENT === 'production') {
  //   LogRocket.init('qhftwb/website-prod-s5v2o', {
  //     rootHostname: 'expo.dev',
  //     dom: {
  //       inputSanitizer: true,
  //     },
  //   });
  //   setupLogRocketReact(LogRocket);
  // }
  if (!process.env.DEPLOYMENT_ENVIRONMENT || process.env.DEPLOYMENT_ENVIRONMENT === 'development') {
    LogRocket.init('qhftwb/website-dev-oerji', {
      dom: {
        inputSanitizer: true,
      },
    });
    setupLogRocketReact(LogRocket);
  }
}

export const regularFont = Inter({
  display: 'swap',
  subsets: ['latin'],
  fallback: ['Inter', 'system-ui', 'sans-serif'],
  variable: '--regular-font',
});
export const monospaceFont = Fira_Code({
  weight: '400',
  display: 'swap',
  subsets: ['latin'],
  fallback: ["'Fira Code'", 'monospace'],
  variable: '--monospace-font',
});

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  window?.gtag?.('event', name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,
  });
}

type WebAppProps = {
  router: AppProps['router'];
  Component: NextComponentType<NextPageContext, null, PageProps>;
  // pageProps is an object with the initial props that were preloaded for the page by one of the data fetching methods, otherwise it's an empty object.
  pageProps: PageProps;
};

export default function WebApp({ router, Component, pageProps }: WebAppProps) {
  const {
    accountName: _accountName,
    currentUser: _currentUser,
    experimentationConfig,
    initialApolloState,
    headers,
    isYou: _isYou,
    pathname: _pathname,
    params: _params,
    resolvedUrl,
    userAgent,
    currentDateAsString: _currentDateAsString,
    isServerSideRendered,
    cookies,
  } = pageProps;

  const featureGateQueryParams = getPersistentFeatureGateQueryParams(
    hasURLFeatureGateOverrides(router.query)
      ? router.query
      : getCookieFeatureGateQueryParams(cookies)
  );
  const apiV2Client = new APIV2Client(featureGateQueryParams);
  const apolloClient = useRef(
    createClient({
      headers,
      initialState: initialApolloState,
      featureGateQueryParams,
      link: [createSudoUpgradeLink(apiV2Client)],
    })
  );

  const { data } = useCurrentUserQuery({
    skip: Boolean(_currentUser),
    client: apolloClient.current,
  });

  useNProgress();

  // Populate these values using client-side methods if page isn't server-side rendered
  const params = _params ?? router.query;
  const pathname = _pathname ?? router.pathname;
  const currentUser = _currentUser ?? data?.meUserActor ?? null;

  // Account name resolved from the URL or the current user (for pages without the [account] param).
  const accountName =
    (_accountName ?? (getParamString(params.account) || currentUser?.username))?.toLowerCase() ??
    null;

  // Account name resolved for the purpose of search (different from the URL account name in the
  // sense that the user has to have access to it or be an admin).
  const searchAccountName = currentUser?.isExpoAdmin
    ? accountName
    : currentUser?.accounts.find((account) => account.name === accountName)?.name;

  const isYou =
    _isYou ?? (currentUser?.username && accountName ? currentUser.username === accountName : false);
  const currentDateAsString = useRef(_currentDateAsString ?? new Date().toISOString()).current;

  const hasMountedRef = useRef(false);

  useEffect(function didMount() {
    if (!hasMountedRef.current) {
      identifyUser();
      // Track the first page view
      handlePageViewTracking();
      redirectToViewerRoute();
      printDevMessages(currentUser?.isExpoAdmin);
      hasMountedRef.current = true;
    }
  }, []);

  useEffect(
    function trackPageView() {
      router.events.on('routeChangeComplete', handlePageViewTracking);

      return () => {
        router.events.off('routeChangeComplete', handlePageViewTracking);
      };
    },
    [router.events]
  );

  // TODO(simek): figure out how to alter the logic to avoid this replace
  function redirectToViewerRoute() {
    if (isYou && currentUser) {
      if (pathname === '/snacks') {
        void router.replace('/snacks', `/accounts/${currentUser.username}/snacks`);
      }
    }
  }

  function identifyUser() {
    // For now we are skipping identifying SSO users - TBD how to identify them as they do not have a unique email.
    if (currentUser && currentUser.__typename === 'User') {
      const { id, username, email } = currentUser;

      identify(id, { username, email: email ?? '' });
    }

    if (params?.ref) {
      // Only identify anonymous users in special cases, otherwise we would use up Segment MTU quota very quickly.
      if (!currentUser) {
        identifyAnonymous();
      }

      track(events.REFERRAL_RECEIVED, {
        ref: params.ref,
        url: router.pathname,
      });
    }
  }

  function handlePageViewTracking(_?: string, options?: { shallow: boolean }) {
    window?.gtag?.('config', googleAnalyticsId, {
      page_path: router.pathname,
      transport_type: 'beacon',
    });

    if (!options?.shallow) {
      track(events.USER_PAGE_VIEWED, { url: router.pathname, userId: currentUser?.id });
    }
  }

  const experimentationManger = experimentationConfig
    ? new ExperimentationManager(experimentationConfig, currentUser?.id)
    : undefined;

  // NOTE(fiberjw): indeterminate urls have [account] and [project] as placeholder strings
  // if the user is logged out, allow them to log in and then be redirected to the selector
  const decodedPathname = decodeURIComponent(resolvedUrl ?? pathname);
  const isIndeterminateLink =
    decodedPathname.includes('[account]') && !decodedPathname.includes('?redirect_uri');

  return (
    <ErrorBoundary
      fallback={
        <Document meta={{ name: 'error' }}>
          <ErrorPage statusCode={500} />
        </Document>
      }>
      <Head>
        <title>Expo</title>
        <style>
          {`html, body, kbd, button, input, select, tspan, text: { fontFamily: ${regularFont.style.fontFamily}, sans-serif; }
            code, pre': { fontFamily: ${monospaceFont.style.fontFamily}, monospace; }`}
        </style>
      </Head>
      <EnvBanner />
      {getGTagScriptTags({ currentDateAsString })}
      {getRudderStackScriptTags()}
      <ThemeProvider>
        <MotionConfig reducedMotion="user">
          <ApolloProvider client={apolloClient.current}>
            <APIV2Context.Provider value={apiV2Client}>
              <FeatureGateURLQueryParamsContextProvider
                featureGateQueryParams={
                  hasURLFeatureGateOverrides(router.query) ? featureGateQueryParams : null
                }>
                <ExperimentationContext.Provider value={experimentationManger}>
                  <FeatureGateContext.Provider
                    value={getFeatureGates(currentUser, featureGateQueryParams)}>
                    <MDXProvider components={MarkdownComponents}>
                      <ToastProvider>
                        <LogsDisplayOptionsProvider>
                          <AuthFormContextProvider>
                            <SearchDialogProvider>
                              <PagePropsContext.Provider value={pageProps}>
                                <SidebarCollapsiblesProvider>
                                  <Navigation
                                    isServerSideRendered={isServerSideRendered}
                                    currentUser={currentUser}
                                    currentDateAsString={currentDateAsString}
                                    pathname={pathname}
                                  />
                                  <Search accountName={searchAccountName} />
                                  {isIndeterminateLink && accountName && currentUser ? (
                                    <LinkResolverPage
                                      {...pageProps}
                                      accountName={accountName}
                                      currentUser={currentUser}
                                      decodedPathname={decodedPathname}
                                    />
                                  ) : (
                                    <Component
                                      {...pageProps}
                                      accountName={accountName}
                                      currentUser={currentUser}
                                      experimentationConfig={experimentationConfig}
                                      resolvedUrl={resolvedUrl}
                                      userAgent={userAgent}
                                    />
                                  )}
                                  <Footer />
                                </SidebarCollapsiblesProvider>
                              </PagePropsContext.Provider>
                            </SearchDialogProvider>
                          </AuthFormContextProvider>
                        </LogsDisplayOptionsProvider>
                      </ToastProvider>
                    </MDXProvider>
                  </FeatureGateContext.Provider>
                </ExperimentationContext.Provider>
              </FeatureGateURLQueryParamsContextProvider>
            </APIV2Context.Provider>
          </ApolloProvider>
        </MotionConfig>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
