import { mergeClasses } from '@expo/styleguide';
import { HelpCircleIcon } from '@expo/styleguide-icons/outline/HelpCircleIcon';
import { ComponentType, HTMLAttributes, ReactNode } from 'react';

import { Popover } from '~/ui/components/Popover';
import { CALLOUT } from '~/ui/components/text';

import { Button } from '../Button';
import { LinkBase } from '../LinkBase';

type LinkProps = {
  title: ReactNode;
  Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  href?: string;
  onClick?: () => void;
  className?: string;
  isSelected?: boolean;
  disabled?: boolean;
  disabledHint?: ReactNode;
  destructive?: boolean;
};

export function SidebarLink({
  title,
  Icon,
  href,
  onClick,
  disabled,
  disabledHint,
  isSelected,
  className,
  destructive,
}: LinkProps) {
  return (
    <div className="relative">
      <LinkBase
        prefetch={false}
        className={mergeClasses(
          'flex h-10 cursor-pointer items-center justify-between gap-2 rounded-md pl-3 pr-1',
          !disabled && 'hover:bg-subtle',
          destructive && 'hover:bg-danger',
          isSelected && 'bg-info hover:bg-info',
          className
        )}
        href={href}
        onClick={onClick}
        disabled={disabled}>
        <div className="flex items-center gap-2">
          {Icon && (
            <Icon
              className={mergeClasses(
                isSelected && 'text-link',
                disabled && 'text-icon-quaternary',
                destructive && 'text-icon-danger'
              )}
            />
          )}
          <CALLOUT
            theme={destructive ? 'danger' : disabled ? 'tertiary' : 'default'}
            className={isSelected ? 'text-link' : ''}>
            {title}
          </CALLOUT>
        </div>
        {disabled && disabledHint && (
          <Popover
            trigger={
              <Button
                theme="quaternary"
                leftSlot={<HelpCircleIcon className="icon-md text-icon-secondary" />}
                size="xs"
                className="px-1.5"
                aria-label="Show hint"
              />
            }
            className="min-w-[200px]">
            <div className="p-4">
              <CALLOUT>{disabledHint}</CALLOUT>
            </div>
          </Popover>
        )}
      </LinkBase>
    </div>
  );
}
