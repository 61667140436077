import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';
import { useState } from 'react';

import { ProjectCreationForm } from '~/scenes/Dashboard/Projects/AllProjectsScene/ProjectCreationForm';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { Button } from '~/ui/components/Button';
import { Placeholder } from '~/ui/components/Placeholder';
import { AccountSelector } from '~/ui/components/SidebarNavigation/components/AccountSelector';
import { ProjectSelector } from '~/ui/components/SidebarNavigation/components/ProjectSelector';
import { DEMI, H4, P } from '~/ui/components/text';

import { useGetUserAccountsAndProjectsQuery } from './queries/GetUserAccountsAndProjects.query.generated';

type Props = {
  currentUser: LoggedInProps['currentUser'];
  currentDateAsString: string;
  destinationPath: string;
};

export function LinkResolverScene({ currentUser, currentDateAsString, destinationPath }: Props) {
  const [selectedAccountUsername, setSelectedAccountUsername] = useState(currentUser.username);
  const [selectedProjectSlug, setSelectedProjectSlug] = useState<string | undefined>(undefined);
  const [showProjectCreationForm, setShowProjectCreationForm] = useState(false);

  const { data, loading, error, refetch } = useGetUserAccountsAndProjectsQuery({
    variables: {
      username: selectedAccountUsername,
    },
  });

  const accounts = currentUser.accounts;
  const projects = data?.account.byName.apps;

  const account = accounts.find((account) => account.name === selectedAccountUsername);

  const needsProject =
    destinationPath.includes('[project]') || destinationPath.includes('[projectName]');

  if (error || !account) {
    console.error(error);

    return (
      <Placeholder
        title={`We're having troubles loading your ${needsProject ? 'projects' : 'accounts'}.`}
        description="Click 'reload' to try again"
        loading={loading}
        onClick={async () => {
          try {
            setSelectedAccountUsername(currentUser.username);
            await refetch({
              username: selectedAccountUsername,
            });
          } catch (error) {
            console.error(error);
          }
        }}
        buttonText="Reload"
      />
    );
  }

  const isProjectSelectorDisabled = needsProject && !projects?.length;

  return (
    <div className="mx-auto my-0 flex min-h-[calc(100vh-80px)] w-full max-w-7xl flex-col items-center p-6 py-12">
      <div className="flex w-full max-w-[600px] flex-col gap-6">
        <H4 size="xl">Select an account{needsProject ? ' and project' : ''}</H4>
        <P theme="secondary">
          To visit <DEMI>{destinationPath}</DEMI>, you have to select an account
          {needsProject ? ' and a project' : ''}.
        </P>
        <BoxWrapper>
          <BoxContentContainer size="form" className="flex w-full max-w-[600px] flex-col">
            <div className="flex flex-col gap-4">
              {accounts.length && (
                <AccountSelector
                  header={
                    <P weight="medium" className="flex items-center gap-3">
                      <Users01Icon />
                      Account
                    </P>
                  }
                  selectedUsername={selectedAccountUsername}
                  onSelect={(selected) => {
                    if (selected !== selectedAccountUsername) {
                      setSelectedProjectSlug(undefined);
                    }
                    setSelectedAccountUsername(selected);
                  }}
                  currentUser={currentUser}
                  currentDateAsString={currentDateAsString}
                  accounts={accounts}
                  showCreateOrganization={false}
                  showInvitations={false}
                  applyHref={false}
                />
              )}
              {needsProject ? (
                <>
                  {showProjectCreationForm ? (
                    <BoxWrapper className="overflow-hidden rounded-lg">
                      <ProjectCreationForm
                        onRequestClose={() => {
                          setShowProjectCreationForm(false);
                        }}
                        accountId={account.id}
                        accountName={selectedAccountUsername}
                        onComplete={async (slug, _) => {
                          setSelectedProjectSlug(slug);
                          await refetch({ username: selectedAccountUsername });
                          setShowProjectCreationForm(false);
                        }}
                      />
                    </BoxWrapper>
                  ) : (
                    <ProjectSelector
                      placeholder={
                        isProjectSelectorDisabled ? 'Create a project to continue' : undefined
                      }
                      disabled={isProjectSelectorDisabled}
                      header={
                        <P weight="medium" className="flex items-center gap-3">
                          <FolderIcon />
                          Project
                        </P>
                      }
                      rightSlot={
                        <Button
                          size="xs"
                          theme="tertiary"
                          onClick={() => {
                            setShowProjectCreationForm(true);
                          }}>
                          Create Project
                        </Button>
                      }
                      projectSlug={selectedProjectSlug}
                      accountName={selectedAccountUsername}
                      showAllProjects={false}
                      onSelect={(slug) => {
                        setSelectedProjectSlug(slug);
                      }}
                    />
                  )}
                </>
              ) : null}
            </div>
          </BoxContentContainer>
          <div className="flex items-center justify-end rounded-b-lg bg-subtle p-4">
            <Button
              disabled={needsProject && !selectedProjectSlug}
              onClick={() => {
                const newPath =
                  needsProject && selectedProjectSlug
                    ? destinationPath
                        .replace('[account]', selectedAccountUsername)
                        .replace('[project]', selectedProjectSlug)
                        .replace('[projectName]', selectedProjectSlug)
                    : destinationPath.replace('[account]', selectedAccountUsername);

                const newURL = new URL(newPath + window.location.hash, window.location.origin);

                // It's necessary to do a full reload here since NextJS transitions sometimes end up rendering placeholder
                // results even when the value in the omnibar is filled out
                window.location.replace(newURL);
              }}
              rightSlot={<ArrowRightIcon />}>
              Visit Link
            </Button>
          </div>
        </BoxWrapper>
      </div>
    </div>
  );
}
